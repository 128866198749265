<template>
<b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mr-4" no-caret right no-flip style="float:right">
    <template v-slot:button-content>
        <v-btn class="mx-2" fab dark color="indigo" style="background:#0881F9">
            <v-icon>mdi-plus</v-icon>
        </v-btn>

    </template>

    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
                <span class="navi-icon">
                    <i class="flaticon2-drop"></i>
                </span>
                <span class="navi-text">Action</span>
            </a>
        </b-dropdown-text>
        <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
                <span class="navi-icon">
                    <i class="flaticon2-drop"></i>
                </span>
                <span class="navi-text">Create Draft</span>
            </a>
        </b-dropdown-text>
        <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
                <span class="navi-icon">
                    <i class="flaticon2-drop"></i>
                </span>
                <span class="navi-text">Create Ad</span>
            </a>
        </b-dropdown-text>
    </div>
    <!--end::Navigation-->
</b-dropdown>
</template>

<style lang="scss">
.custom-v-dropdown {
    &.dropdown-toggle {
        padding: 0;

        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    &.dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;

        .b-dropdown-text {
            padding: 0;
        }
    }
}
</style>

<script>
export default {
    name: "dropdown-6",
    components: {},
    mounted() {}
};
</script>
