<template>
<div>
   <div class="row mb-10" data-app>
        <div class="col-xl-12">
            <div class="inf-table">
                <!-- <span class="badge">new</span> -->
                <div class="row mb-4 mt-4">
                    <div class="col-lg-6">
                        <h2 class="ml-5" style="font-weight:bold;color:#00">PERFORMANCE PLAN</h2>
                        <span class="ml-5" style="color:#5EBB46">5-11 October, 2020</span>
                    </div>
                    <div class="col-lg-6" style="align-items: center;justify-content: flex-end; display: flex;">

                        <b-dropdown id="dropdown-left" style="float:right" text="Mega Influencers" variant="primary" class="m-2">
                            <b-dropdown-item href="#">Action</b-dropdown-item>
                            <b-dropdown-item href="#">Another action</b-dropdown-item>
                            <b-dropdown-item href="#">Something else here</b-dropdown-item>
                        </b-dropdown>
                        <v-btn @click="dialog = true" style="float:right;background:#0881F9;height: 30px;width: 30px;" class="mx-1 ml-4 mr-4" fab dark color="indigo">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </div>
            <div class="row mt-30 mb-30" style="text-align:center">
                <div class="col-lg-12">
                    <h1 style="color:#CCCCCC">Influencers list is empty</h1>
                    <span style="font-weight:bold;color:#000">Click on the plus to start adding influencers</span>
                </div>
            </div>
            </div>
        </div>
    </div>
    <v-dialog v-model="dialog" class="scrollable" persistent min-height="700px" max-width="900px">
        <v-card class="scrollable">
            <v-card-title class="model-title">
                <h2 class="headline">Move Influencer to</h2>
                <span class="model-sub-title">Bath & Body Works</span><br>
                <b-dropdown toggle-class="custom-v-dropdown btn btn-white font-weight-bold dropdown-heres" text="for 12-19 October, 2020">
                    <b-dropdown-item>August 2020</b-dropdown-item>
                    <b-dropdown-item>August 2050</b-dropdown-item>
                    <b-dropdown-item>Third Action</b-dropdown-item>
                </b-dropdown>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>

                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Type</h6>
                            <ul class="type-list">
                                <li :class="{'active' : fType == 0}" @click="activeType(0)">Mega Influencers</li>
                                <li :class="{'active' : fType == 1}" @click="activeType(1)">Photo Ads</li>
                                <li :class="{'active' : fType == 2}" @click="activeType(2)">Micro Influencers</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Date</h6>
                            <b-form-input :id="`type-date`" type="date"></b-form-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Influencer</h6>
                            <b-form-input v-if="ChoosenInfluencer.length == 0" v-model="infSearch" @keyup="SearchA" placeholder="start typing to search..." type="text" required></b-form-input>
                            <div v-else class="single-info">
                                <img :src="ChoosenInfluencer.img">
                                <span>{{ChoosenInfluencer.name}}</span>
                                <span @click="DeleteInfl" class="close">
                                    <v-icon style="color:rgb(29 29 29)" dark>mdi-minus</v-icon>
                                </span>
                            </div>
                            <ul class="influencer-list" v-if="infSearch.length > 0">
                                <li v-for="(item,index) in InfluencerSSearch" :key="index" @click="ChooseOne(item)">
                                    <img :src="item.img">
                                    <span>{{item.name}}</span>
                                </li>
                            </ul>
                        </v-col>
                        <v-col sm="6" md="6">
                            <h6 class="input-label">Expected Orders</h6>
                            <b-form-input v-model="Expected" placeholder="add here" type="text" required></b-form-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Cost</h6>
                            <span v-if="Influencer == 0" class="label-sub">Add influencer to get cost </span>
                            <div v-else>
                                <b-form-input placeholder="Cost" type="text" required></b-form-input>
                            </div>

                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Commission Expected</h6>
                            <span v-if="Expected == 0" class="label-sub">Add expected orders to generate </span>
                            <div v-else>
                                <b-form-input placeholder="Commission" type="text" required></b-form-input>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Phase</h6>
                            <ul class="Phase-list">
                                <li style="background-color:#FFF0D9;color:#F3A430" :class="{'active' : Phase == 0}" @click="activePhase(0)">Regular</li>
                                <li style="background-color:#FFFCBF;color:#C9BF18" :class="{'active' : Phase == 1}" @click="activePhase(1)">New</li>
                                <li style="background-color:#C8FFBA;color:#5EBB46" :class="{'active' : Phase == 2}" @click="activePhase(2)">Test Again</li>
                                <li style="background-color:#FFD5D6;color:#E2060B" :class="{'active' : Phase == 3}" @click="activePhase(3)">Test</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Profit Expected</h6>
                            <span v-if="Expected == 0" class="label-sub">Add expected orders to generate </span>
                            <div v-else>
                                <b-form-input placeholder="Profit" type="text" required></b-form-input>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">Coupon Code</h6>
                            <b-form-input placeholder="ROI" type="text" required></b-form-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h6 class="input-label">ROI Expected</h6>
                            <span v-if="Expected == 0" class="label-sub">Add expected orders to generate </span>
                            <div v-else>
                                <b-form-input placeholder="ROI" type="text" required></b-form-input>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions style="float: right;">
                <v-btn color="red darken-1" class="confirm-btn mr-4" text @click="dialog = false">close</v-btn>
                <v-btn color="blue darken-1" class="confirm-btn" text @click="dialog = false">Confirm</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            fType: 0,
            Phase: null,
            Influencer: 0,
            Expected: 0,
            infSearch: 0,
            ChoosenInfluencer: [],
            InfluencerSS: [{
                    name: 'Adham Mahmoud',
                    img: "media/svg/misc/test2.png"
                },
                {
                    name: 'Ziead',
                    img: "media/svg/misc/test2.png"
                },
            ],
            InfluencerSSearch: []
        }
    },
    methods: {
        CheckRestricted(src, restricted) {
            return !src.split("").some(ch => restricted.indexOf(ch) !== -1);
        },
        SearchA() {
            var myhere = this.InfluencerSS;
            this.InfluencerSSearch = [];
            for (var i = 0; i < myhere.length; i++) {
                if (myhere[i].name.toLowerCase().includes(this.infSearch.toLowerCase())) {
                    this.InfluencerSSearch.push(myhere[i]);
                }
            }
        },
        StartPlan() {
            if (this.dialog == false) {
                this.dialog = true;
            } else {
                this.dialog = false;
            }

        },
        activeType(value) {
            this.fType = value;
        },
        activePhase(value) {
            this.Phase = value;
        },
        ChooseOne(item) {
            this.ChoosenInfluencer = item;
            this.InfluencerSSearch = []
            this.Influencer = item.name
        },
        DeleteInfl() {
            this.ChoosenInfluencer = []
            this.InfluencerSSearch = []
            this.infSearch = 0;
            this.Influencer = 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.model-title {
    display: block;
    text-align: left;

    h2 {
        font-weight: bold;
    }

    .model-sub-title {
        color: #d2d2d2;
    }
}

.type-list {
    list-style: none;
    display: flex;

    li {
        margin-right: 1rem;
        border: 1px solid #A4C5E2;
        padding: 5px 5px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        font-size: 10px;
        border-radius: 5px;
    }

    .active {
        color: #fff;
        background-color: #1374D6;
    }
}

.input-label {

    font-weight: bold;
    color: #000;
    text-align: left;

}

.label-sub {
    text-align: left;
    display: block;
}

input,
select,
.v-select__selections {
    border-radius: 5px;
    border: none;
    background-color: #F3FCFF;

    &:focus {
        background-color: #F3FCFF;
    }
}

.Phase-list {
    list-style: none;
    display: flex;

    li {
        margin-right: 1rem;
        padding: 5px 5px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        font-size: 10px;
        border-radius: 5px;
    }

    .active {
        color: #fff !important;
        background-color: #1374D6 !important;
    }
}

.confirm-btn {
    float: right;
    background-color: #1374D6;
    color: #fff;
    font-weight: bold;
    padding: 5px 2rem;
}

.influencer-list {
    list-style: none;
    background-color: #fff;

    text-align: left;
    box-shadow: 0px 0px 8px 2px #f5f5f5;

    li {
        margin-bottom: 1rem;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background-color: #D2F3F2;
            color: #1C97C3 !important
        }

        &:hover span {
            color: #1C97C3;
        }

        img {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            margin-right: 1rem;
        }

        span {
            font-weight: bold;
            color: #000
        }
    }
}

.single-info {
    margin-bottom: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    background: #D2F3F2;
    text-align: left;
    border-radius: 5px;

    img {
        height: 25px;
        width: 25px;

        margin-right: 1rem;
    }

    span {
        font-weight: bold;
        color: #1C97C3
    }
}
.inf-table{
    padding-top:1rem;
}
</style>
