<template>
  <v-row class="fill-height">
    <v-col lg=3 md=3>
      <div class="tab">
        <span class="title">Allocation</span>
        <div class="box">
            <div>
              <input type="checkbox" class="allocation_check">
              <span>1-8 October 2020</span>
            </div>
            <div>
              <input type="checkbox" class="allocation_check">
              <span>1-8 October 2020</span>
            </div>
            <div>
              <input type="checkbox" class="allocation_check">
              <span>1-8 October 2020</span>
            </div>
            <div>
              <input type="checkbox" class="allocation_check">
              <span>1-8 October 2020</span>
            </div>
            <!-- <label class="container">Four
                <input type="checkbox">
                <span class="checkmark"></span>
              </label> -->
        </div>
      </div>
      <div class="tab">
        <span class="title">Type of ADS</span>
        <div class="box">
            <div>
              <input type="checkbox" class="type_check">
              <span>Micro influencer</span>
            </div>
            <div>
              <input type="checkbox" class="type_check">
              <span>Micro influencer</span>
            </div>
            <div>
              <input type="checkbox" class="type_check">
              <span>Photo Ads</span>
            </div>
        </div>
      </div>
      <div class="tab">
        <span class="title">Status</span>
        <div class="box">
            <div>
              <input type="checkbox" class="status_check">
              <span>New</span>
            </div>
            <div>
              <input type="checkbox" class="status_check">
              <span>Confirm</span>
            </div>
        </div>
      </div>
    </v-col>
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="danger"
          :events="events"
          :type="type"
          :event-color="getEventColor"
          @change="updateRange"
        >
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  export default {
      name: "demo",
    data() {
        return{
            today: '2019-01-10',
    tracked: {
      '2019-01-09': [23, 45, 10],
      '2019-01-08': [10],
      '2019-01-07': [0, 78, 5],
      '2019-01-06': [0, 0, 50],
      '2019-01-05': [0, 10, 23],
      '2019-01-04': [2, 90],
      '2019-01-03': [10, 32],
      '2019-01-02': [80, 10, 10],
      '2019-01-01': [20, 25, 10],
    },
    colors: ['#1867c0', '#fb8c00', '#972525'],
    category: ['Development', 'Meetings', 'Slacking'],
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['#ffe0e0', '#8ab9ee'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      
      updateRange ({ start, end }) {
        this.events = [ {
           name: 'Cost AED 2500',
            start: '2021-02-09',
            color: this.colors[1],
        },
        {
           name: 'ROI 3.4',
            start: '2021-02-09',
            color: this.colors[0],
        },
        {
           name: 'Cost AED 3000',
            start: '2021-02-25',
            color: this.colors[1],
        },
        {
           name: 'ROI 2.4',
            start: '2021-02-25',
            color: this.colors[0],
        }
        ];
      //   const min = new Date(`${start.date}T00:00:00`)
      //   const max = new Date(`${end.date}T23:59:59`)
      //   const days = (max.getTime() - min.getTime()) / 86400000
      //   const eventCount = this.rnd(days, days + 20)
      //   for (let i = 0; i < eventCount; i++) {
      //     const allDay = this.rnd(0, 3) === 0
      //     const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //     const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //     const second = new Date(first.getTime() + secondTimestamp)
      //     events.push({
      //       name: this.names[this.rnd(0, this.names.length - 1)]+' '+ this.colors[this.rnd(0, this.colors.length - 1)],
      //       start: first,
      //       end: second,
      //       color: this.colors[this.rnd(0, this.colors.length - 1)],
      //       // timed: !allDay,
      //     })
      //   }
      //   this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      // showEvent ({ nativeEvent, event }) {
      //   const open = () => {
      //     this.selectedEvent = event
      //     this.selectedElement = nativeEvent.target
      //     setTimeout(() => {
      //       this.selectedOpen = true
      //     }, 10)
      //   }
      //   if (this.selectedOpen) {
      //     this.selectedOpen = false
      //     setTimeout(open, 10)
      //   } else {
      //     open()
      //   }
      //   nativeEvent.stopPropagation()
      // },
    },
  }
</script>
<style lang="scss">
.v-calendar-weekly__day-label{
    .v-btn--fab{
        background: red;
    }
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: transparent !important;
    float: right !important;
    pointer-events: none !important;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover {
    background-color: transparent !important;
}
.v-present{
    .v-calendar-weekly__day-label{
      .v-btn--fab{
        font-size: 1.5rem !important;
      }
    }
}
.tab{
  margin-bottom: 10%;
  .title{
    font-weight: 600;
    margin-bottom: 1%;
  }
  .box{
    padding: 1.5rem;
    box-shadow: 0px 0px 10px 1px #d0cccc;
    border-radius: 10px;
    margin-top: 1rem;
    div{
      padding-bottom:1.5rem;
    }
    input{
      margin-right: 1rem;
    }
    span{
      font-weight: 700;
    }
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
