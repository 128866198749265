<template>
  <div>
    <div v-if="currentSection === 'campaign'">
      <ag-grid-vue
        class="ag-grid-table ag-theme-material table table-responsive"
        :context="context"
        :suppressDragLeaveHidesColumns="true"
        :gridOptions="gridOptions"
        :defaultColDef="defaultColDef"
        :rowData="campaigns"
        :animateRows="true"
        rowSelection="multiple"
        @rowSelected="onSelectCampaign"
      >
      </ag-grid-vue>
      <span class="bottom">
        <b-pagination
          style="display: inline-flex"
          v-model="currentPage"
          aria-controls="my-table"
        ></b-pagination>
        <span style="float: right" class="pr-5">
          <b-button
            class="deleteAll-btn"
            v-b-modal.delete-campaign
            :disabled="!selectedCampaign.length"
            >Delete All
          </b-button>
        </span>
      </span>

      <!-- Edit Modal Component -->
      <!-- <edit-campaign :ClientName="ClientName" :row="edit_row" v-if="edit_row" /> -->
      <b-modal
        id="edit-campaign-modal"
        title="Overflowing Content"
        hide-header
        hide-footer
      >
        <EditCampaign
          v-if="edit_row"
          type="edit"
          :campaignData="edit_row"
          :ClientName="ClientName"
          :calenderDate="calenderDate"
        />
      </b-modal>

      <!-- view Plan Modal -->
      <b-modal
        id="view_plan"
        ok-title="APPROVE SELECTED"
        cancel-title="REJECT SELECTED"
        dialog-class="view-media-plan-modal"
        @ok="handleOk"
      >
        <template slot="modal-title">
          <h3>{{ campaignTitle }}</h3>
          <p class="modal-date">{{ campaignPeriod }}</p>
        </template>
        <div>
          <ag-grid-vue
            class="ag-theme-material table table-responsive"
            style="width: 100%; height: 300px"
            :gridOptions="period_gridOptions"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :rowData="period_arr"
            :animateRows="true"
            rowSelection="single"
            @cellClicked="onCellClicked"
            @rowSelected="onSelectAds"
          >
          </ag-grid-vue>
          <ag-grid-vue
            class="ag-theme-material table table-responsive"
            style="width: 100%; height: 80px; margin-top: -77px;margin-top:1.5em;"
            :gridOptions="period_gridOptions_sum"
            :defaultColDef="defaultColDef"
            :rowData="summ_arr"
            :headerHeight="0"
            :overlayNoRowsTemplate="noRowsTemplate"
            :animateRows="true"
          >
          </ag-grid-vue>

          <b-modal
            id="comment"
            title="Second Modal"
            @ok="addComment"
            :ok-title="'ADD'"
          >
            <template slot="modal-title">
              <h3>{{ selectedRowForComment.type }}</h3>
              <span class="modal-date"
                >{{
                  new Date(
                    selectedRowForComment.planned_date
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                }}
                <span class="text-muted">{{
                  selectedRowForComment.client_name
                }}</span>
              </span>
            </template>
            <div class="row">
              <div class="modal-comment-box">
                <p>Cost</p>
                <h3>
                  {{ selectedRowForComment.cost_currency }}
                  {{ selectedRowForComment.cost }}
                </h3>
              </div>
              <div class="modal-comment-box">
                <p>Expected Orders</p>
                <h3>{{ selectedRowForComment.exp_orders }}</h3>
              </div>
              <div class="modal-comment-box">
                <p>Commission Expected</p>
                <h3>
                  {{ selectedRowForComment.commission_exp_currency }}
                  {{ selectedRowForComment.commission_exp }}
                </h3>
              </div>
              <div class="modal-comment-box">
                <p>Profit Expected</p>
                <h3>
                  {{ selectedRowForComment.profit_exp_currency }}
                  {{ selectedRowForComment.profit_exp }}
                </h3>
              </div>
              <div class="modal-comment-box">
                <p>ROI Expected</p>
                <h3>{{ selectedRowForComment.roi }}</h3>
              </div>
              <div class="modal-comment-box">
                <p>Coupon</p>
                <h3>{{ selectedRowForComment.code }}</h3>
              </div>
            </div>
            <div class="mt-4">
              <p>Add a Comment</p>
              <p>
                <b
                  >for {{ selectedRowForComment.type }}
                  {{
                    new Date(
                      selectedRowForComment.planned_date
                    ).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</b
                >
              </p>
              <textarea
                class="form-control"
                placeholder="add comment here.."
                name=""
                id=""
                cols="30"
                rows="3"
                v-model="dataToPost.comment"
              ></textarea>
            </div>
          </b-modal>
        </div>
      </b-modal>
      <div class="deleteAllModal">
        <b-modal
          dialog-class="deleteAllModal"
          id="delete-campaign"
          ok-variant="danger"
          ok-title="Delete"
          @ok="deleteAllConfirmation"
          size="sm"
          centered
        >
          <h5>Are you sure you want to delete all following offers?</h5>
          <ul class="ml-3">
            <li v-for="(item, i) in selectedCampaign" v-bind:key="i">
              {{ item.title }}
            </li>
          </ul>
        </b-modal>
      </div>
    </div>
    <div v-if="currentSection === 'allocation'">
      <ag-grid-vue
        class="ag-grid-table ag-theme-material table table-responsive"
        :context="context"
        :suppressDragLeaveHidesColumns="true"
        :gridOptions="gridOptions"
        :defaultColDef="defaultColDef"
        :rowData="campaigns"
        :animateRows="true"
        rowSelection="multiple"
        @rowSelected="onSelectCampaign"
        v-show="isShow"
      >
      </ag-grid-vue>
      <allocation />
    </div>
    <div v-if="currentSection === 'ads'">
      <ag-grid-vue
        class="ag-grid-table ag-theme-material table table-responsive"
        :context="context"
        :suppressDragLeaveHidesColumns="true"
        :gridOptions="gridOptions"
        :defaultColDef="defaultColDef"
        :rowData="campaigns"
        :animateRows="true"
        rowSelection="multiple"
        @rowSelected="onSelectCampaign"
        v-show="isShow"
      >
      </ag-grid-vue>
      <ads
        :selectedCampaign="selectedCampaign"
        :ClientName="ClientName"
        :isInfluencer="false"
        :platform_type="'PERFORMANCE'"
      />
    </div>
  </div>
</template>
<script>
import ApiService from "../../core/services/api.service";
import { AgGridVue } from "ag-grid-vue";
import CellRendererDropdown from "@/view/components/CellStatusDropdownPerfAds.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";
import CellRendererPeriod from "@/view/pages/CellRenderPeriod.vue";
import CellRendererComment from "@/view/pages/CellRenderCommentModal.vue";
import CellRenderTarget from "../pages/CellRenderTarget.vue";
// import editCampaign from "../pages/performance_management/campaigns/Edit_Campaign.vue";
import { mapGetters } from "vuex";
import EditCampaign from "@/view/pages/performance_management/campaigns/Create_Campaign.vue";
import allocation from "@/view/components/allocation";
import ads from "@/view/components/ads";

export default {
  name: "campaign",
  props: ["ClientName", "calenderDate", "currentSection"],
  components: {
    AgGridVue,
    CellRendererDropdown,
    CellRendererAction,
    CellRendererComment,
    CellRendererPeriod,
    CellRenderTarget,
    EditCampaign,
    allocation,
    ads,
  },
  watch: {
    campaigns: function(clientsList) {
      this.$emit("campaignUpdated", clientsList);
    },
    selectedCampaign: function(oldCode, newCode) {
      this.changeSelection();
    },
  },
  data() {
    return {
      selectedAds: [],
      dataToPost: {
        influencer_id: "5ff2ee84103600006e007975",
        comment: "",
      },
      currentPage: 1,
      isShow: false,
      context: {},
      comment: "",
      client_id: this.$route.params.id,
      editable_row: null,
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
      },
      noRowsTemplate: "<span></span>",
      gridOptions: {
        frameworkComponents: {
          //   CellRendererAvatars: CellRendererAvatars,
          //   CellRendererName: CellRendererName,
          //   CellRendererMATERIALS: CellRendererMATERIALS,
          //   CellRendererCoupon: CellRendererCoupon,
          //   CellRendererDropdown: CellRendererDropdown,
          //   CellRendererAction: CellRendererAction
        },
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "NAME",
            field: "title",
            filter: true,
          },
          {
            headerName: "PERIOD",
            filter: true,
            suppressSorting: false,
            selectable: false,
            cellRendererFramework: "CellRendererPeriod",
          },
          {
            headerName: "BUDGET",
            field: "budget",
            filter: false,
            valueGetter(params) {
              return `${params.data.budget} ${params.data.budget_currency}`;
            },
          },
          // {
          //   headerName: "TARGET",
          //   field: "target",
          //   filter: false,
          //   valueGetter(params) {
          //     return `${params.data.target} ${params.data.target_currency}`;
          //   },
          // },
          {
            headerName: "TARGET",
            cellRendererFramework: "CellRenderTarget",
            suppressSorting: true,
          },
          {
            headerName: "ACTUAL COST",
            field: "actual_cost",
            minWidth: 100,
            filter: false,
            valueGetter(params) {
              return `${params.data.actual_cost} ${params.data.actual_cost_currency}`;
            },
          },
          {
            headerName: "COMMISSION",
            field: "commission",
            minWidth: 100,
            filter: false,
            valueGetter(params) {
              return `${params.data.commission} ${params.data.commission_currency}`;
            },
          },
          {
            headerName: "PROFIT",
            field: "profit",
            minWidth: 100,
            filter: false,
            valueGetter(params) {
              return `${params.data.profit} ${params.data.profit_currency}`;
            },
          },
          {
            headerName: "STATUS",
            minWidth: 150,
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },
          {
            headerName: "ACTION",
            Width: 80,
            cellRendererFramework: "CellRendererAction",
            suppressSorting: false,
            selectable: false,
          },
        ],
      },
      selectedCampaign: [],
      period_gridOptions: {
        frameworkComponents: {
          // CellRendererAvatars: CellRendererAvatars,
          // CellRendererName: CellRendererName,
          // CellRendererMATERIALS: CellRendererMATERIALS,
          // CellRendererCoupon: CellRendererCoupon,
          // CellRendererDropdown: CellRendererDropdown,
          // CellRendererAction: CellRendererAction
        },
        alignedGrids: [],
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 50,
            class: "grid-checkbox",
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "DATE",
            field: "planned_date",
            minWidth: 100,
            filter: true,
            cellRenderer: (params) => {
              return `<span>${params.data.planned_date}
              </br>
              <span class="status-class">${params.data.status}</span>
              </span>`;
            },
          },
          {
            headerName: "TYPE",
            field: "type",
            filter: true,
          },
          {
            headerName: "COST",
            field: "cost",
            filter: false,
            valueGetter(params) {
              return `${params.data.cost} ${params.data.cost_currency}`;
            },
          },
          {
            headerName: "EXPECTED ORDERS",
            field: "exp_orders",
            filter: false,
          },
          {
            headerName: "COMMISSION",
            field: "commission_exp",
            filter: false,
            valueGetter(params) {
              return `${params.data.commission_exp} ${params.data.commission_exp_currency}`;
            },
          },
          {
            headerName: "PROFIT",
            field: "profit_exp",
            filter: false,
            valueGetter(params) {
              return `${params.data.profit_exp} ${params.data.profit_exp_currency}`;
            },
          },
          {
            headerName: "ROI",
            field: "roi",
            filter: false,
          },
          {
            headerName: "COUPON",
            field: "code",
            filter: false,
            cellRenderer: (params) => {
              return `<span class="code-class">${
                params.data.code ? params.data.code : "-"
              }</span>`;
            },
          },
          {
            headerName: "COMMENT",
            field: "comment",
            Width: 80,
            cellRendererFramework: "CellRendererComment",
            // cellRenderer:(params)=>{
            //     return `<button class="fa fa-comment-dots"></button>`
            // },
            suppressSorting: true,
          },
          {
            headerName: "STATUS",
            width: 80,
            // field:"status",
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },
        ],
      },
      period_gridOptions_sum: {
        frameworkComponents: {
          // CellRendererAvatars: CellRendererAvatars,
          // CellRendererName: CellRendererName,
          // CellRendererMATERIALS: CellRendererMATERIALS,
          // CellRendererCoupon: CellRendererCoupon,
          // CellRendererDropdown: CellRendererDropdown,
          // CellRendererAction: CellRendererAction
        },
        alignedGrids: [],
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 50,
            class: "grid-checkbox",
            checkboxSelection: false,
            headerCheckboxSelection: false,
          },
          {
            headerName: "",
            field: "planned_date",
            minWidth: 100,
            filter: true,
          },
          {
            headerName: "",
            field: "type",
            filter: true,
          },
          {
            headerName: "",
            field: "cost",
            filter: false,
          },
          {
            headerName: "",
            field: "exp_orders",
            filter: false,
            cellRenderer: (params) => {
              return `<b>AED ${params.data.exp_orders}</b>`;
            },
          },
          {
            headerName: "",
            field: "commission_exp",
            filter: false,
            cellRenderer: (params) => {
              return `<b>AED ${params.data.commission_exp}</b>`;
            },
          },
          {
            headerName: "",
            field: "profit_exp",
            filter: false,
            cellRenderer: (params) => {
              return `<b>AED ${params.data.profit_exp}</b>`;
            },
          },
          {
            headerName: "",
            field: "roi",
            filter: false,
            cellRenderer: (params) => {
              return `<b>${params.data.roi}</b>`;
            },
          },
          {
            headerName: "",
            field: "code",
            filter: false,
          },
          {
            headerName: "",
            field: "comment",
            Width: 80,
            suppressSorting: true,
          },
          {
            headerName: "",
            width: 80,
            suppressSorting: true,
          },
        ],
      },
      period_arr: [],
      selectedRowForComment: {},
      summ_arr: [],
      getRowHeight: null,
    };
  },
  computed: {
    ...mapGetters(["CampaignArrayGetters"]),
    campaigns() {
      return this.$store.getters.CampaignArrayGetters;
    },
    edit_row: {
      get() {
        return this.editable_row;
      },
      set() {
        return this.editable_row;
      },
    },
    campaignTitle() {
      return this.selectedCampaign &&
        this.selectedCampaign.length &&
        this.selectedCampaign[0].title
        ? this.selectedCampaign[0].title
        : "";
    },
    campaignPeriod() {
      return this.selectedCampaign &&
        this.selectedCampaign.length &&
        this.selectedCampaign[0].start_date
        ? `${new Date(this.selectedCampaign[0].start_date).toLocaleDateString(
            "en-US",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
            }
          )}-${new Date(this.selectedCampaign[0].end_date).toLocaleDateString(
            "en-US",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
            }
          )}`
        : "";
    },
  },
  created() {
    console.log("created at Campaign");
  },
  beforeMount() {
    this.context = {
      parent: this,
      viewable: true,
      editable: true,
      deleteable: false,
    };
    this.period_gridOptions.alignedGrids.push(this.period_gridOptions_sum);
    this.period_gridOptions_sum.alignedGrids.push(this.period_gridOptions);
    this.getRowHeight = (params) => {
      if (params.node && params.node.detail) {
        var offset = 80;
        var allDetailRowHeight =
          params.data.callRecords.length *
          params.api.getSizesForCurrentTheme().rowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return allDetailRowHeight + gridSizes.headerHeight + offset;
      }
    };
  },
  mounted() {
    // let rows =  this.$store.getters.CampaignGetters;
    // console.log('row',rows[0])
    // if(rows.length>0){
    //   this.onSelectCampaign(rows[0]);
    // }
    // selecte campaign id
    this.$store.dispatch("CAMPAIGN_ACTION", []);
  },
  updated() {
    console.log("Update Cycle of Campaigns");
    // this.getMediaPlans();
  },
  destroyed() {
    console.log("destroy of campaigns");
  },
  methods: {
    Confirm_selected() {
      this.$bvModal.hide('view_plan');
    },
    onSelectAds(ads) {
      this.selectedAds = this.gridApi.getSelectedRows();
    },
    async handleOk() {
      console.log("COMMEMME");
      const APIURL = "/ads/status";
      let payload = this.selectedAds.map((x) => {
        return {
          ad_id: x._id,
          status: "Confirm",
        };
      });
      try {
        this.isLoading = true;
        let response = await ApiService.post(APIURL, payload);
        this.selectedAds = [];
        console.log("response ", response.data.message);
        this.checkAdsStatus();
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
    async checkAdsStatus() {
      await this.getMediaPlans();
      let isAllAdsConfirm = this.allocation.every(
        (x) => x.status.toLowerCase() == "confirm"
      );

      // if all ads are confirm then we have to update campaign status to Approved.
      if (isAllAdsConfirm) {
        await this.updateCampaignStatus();
        this.Confirm_selected();
        win
        this.$emit("fetch");
      }
    },
    async updateCampaignStatus() {
      console.log("CAMPID", this.campaignData.campaign_id)
      const APIURL = "/campaigns/status";

      let payload = {
        client_id: this.$route.params.id,
        campaign_id: this.campaignData.campaign_id,
        status: "Approved",
      };
      let res = await ApiService.post(APIURL, payload);
      console.log("response ", res.data.message);
      window.location.reload();

    },
    // fetchCities() {
    //   ApiService.get(`/countries/all-city`).then((response) => {
    //     console.log(response, "welcome");
    //   });
    // },
    async getMediaPlans() {
      if (!this.selectedCampaign || !this.selectedCampaign.length) return;
      let res = await ApiService.post("/ads/media-plan", {
        campaign_id: this.selectedCampaign[0]._id,
      });
      console.log("campaign_id", res.data.data.data);
      this.period_arr = res.data.data.data;
      //  this.campaign_id = res.data.data;
      this.allocation = res.data.data.data;
      this.campaignData = res.data.data;
      let sum_roi = 0;
      let sum_exp_orders = 0;
      let sum_commission_exp = 0;
      let sum_profit_exp = 0;
      for (let i = 0; i < this.period_arr.length; i++) {
        if (this.period_arr[i].roi) {
          sum_roi = sum_roi + Number(this.period_arr[i].roi);
        }
        if (this.period_arr[i].exp_orders) {
          sum_exp_orders =
            sum_exp_orders + Number(this.period_arr[i].exp_orders);
        }
        if (this.period_arr[i].commission_exp) {
          sum_commission_exp =
            sum_commission_exp + Number(this.period_arr[i].commission_exp);
        }
        if (this.period_arr[i].profit_exp) {
          sum_profit_exp =
            sum_profit_exp + Number(this.period_arr[i].profit_exp);
        }
      }
      this.summ_arr = [
        {
          roi: sum_roi,
          exp_orders: sum_exp_orders,
          commission_exp: sum_commission_exp,
          profit_exp: sum_profit_exp,
        },
      ];
    },
    async fetchData() {
      console.log(this.$route.params.id);
      let dataToPost = {
        client_id: this.$route.params.id,
        page: this.currentPage,
        per_page: this.perPage,
      };
      let response = await ApiService.post(
        "campaigns/all-influencer",
        dataToPost
      );
      this.campaigns = response.data.data.data;
      this.campaignData = response.data.data.data;
      // this.rows = response.data.data.total;
      // if(this.$route.query)
      // if (this.$route.query.month) {
      //   this.filterData(this.$route.query.month);
      //   this.currentdDate = this.$route.query.month
      // } else {
      //   this.filterData(this.currentdDate);
      // }
      // console.log(this.campaigns.data);
    },
    async deleteAllConfirmation() {
      let payload = {
        client_id: this.$route.params.id,
        camp_ids: this.selectedCampaign.map((x) => x._id),
      };
      await ApiService.post("/campaigns/delete", payload);
      this.selectedCampaign = [];
      // this.fetchData();
      window.location.reload();
    },
    addComment() {
      this.dataToPost.influencer_id = this.selectedRowForComment.influencer_id;
      ApiService.post("/influencers/comment", this.dataToPost).then(
        (response) => {
          console.log("response ", response.data.data.message);
          this.isLoading = false;
          this.$bvModal.hide("edit-campaign-modal");
          this.$emit("refresh", response.data.data.message);
        }
      );
      console.log("dasd", this.comment);
    },
    changeSelection() {
      this.$store.dispatch("CAMPAIGN_ACTION", this.selectedCampaign);
      this.getMediaPlans();
    },
    // async deleteAllConfirmation() {
    //   let payload = {
    //     client_id: this.$route.params.id,
    //     camp_ids: this.selectedCampaign.map((x) => x._id),
    //   };
    //   await ApiService.delete("/campaigns/all-performance", payload);
    //   this.selectedCampaign = [];
    //   this.fetchData();
    // },
    onGridReady(params) {
      console.log(params, "+++");
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onSelectCampaign(campaign) {
      // console.log("caalling ", campaign)
      if (!campaign.node.selected) {
        let index = this.selectedCampaign.indexOf(campaign.data);
        this.selectedCampaign.splice(index, 1);
        return;
      }
      this.campaigns.find((x) => {
        if (x._id === campaign.data._id) {
          this.selectedCampaign.push(campaign.data);
          return;
        }
      });
    },
    viewGridAction(data) {
      // console.log("View ",data)
      this.$bvModal.show("view_plan");
    },
    editGridAction(data) {
      // console.log("Edit ",data);
      this.editable_row = data;
      if (this.$route.fullPath.startsWith("/influencer")) {
        this.$bvModal.show("edit-campaign-modal");
      } else {
        this.$bvModal.show("edit-campaign-modal");
      }
    },
    deleteGridAction(data) {
      let dataToPost = {
        client_id: this.$route.params.id,
      };
      console.log("Delete", data);
    },
    onSelectperiod(data) {
      this.selectedRowForComment = data.data;
    },
    onCellClicked(cell) {
      if (cell.column.colId == "comment") {
        this.selectedRowForComment = cell.data;
      }
    },
  },
};
</script>
<style>
.ag-theme-material .ag-cell {
  border: 1px solid transparent;
  line-height: unset;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss" scoped>
* {
  font-family: Poppins;
}
.infno {
  color: black;
  font-weight: bold;
  margin-right: 14px;
}
.brief_title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.select-image {
  width: 32px;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.brief_date {
  font-size: 15px;
  font-weight: 600;
  color: #f76d67;
}
.input_description {
  font-weight: bold;
  font-size: 14px;
  background-color: #f3f6f9;
  height: 10rem;
}
.description_label .label {
  font-weight: bold !important;
}
.add-btn {
  float: right;
  width: 15rem;
  height: 3.5rem;
  background-color: #1374d6;
  border-radius: 10px;
  font-size: 1.3rem;
  color: #ffffff;
}
.label_weight {
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: 0.02rem;
  color: black;
}
.text {
  font-size: 14px;
  font-weight: 500;
}
.custom-input {
  height: 45px;
  background-color: #f3f6f9;
}
.InfDetPlat {
  border-right: 0.2rem solid #d7dadc;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  /* padding: inherit !important; */
  display: inline-flex !important;
  padding: 0em 0.3em;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background-color: #f3f6f9;
  height: 4rem;
  width: fit-content;
}
.InfDetViews {
  border-right: 0.2rem solid #d7dadc;
  border-radius: 0.4rem 0rem 0rem 0.4rem;
  /* padding: inherit !important; */
  display: inline-flex !important;
  padding: 0.4em 0.3em;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background-color: #f3f6f9;
  height: 4rem;
  width: fit-content;
}
.InfDetFlag {
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  /* padding: inherit !important; */
  display: inline-flex !important;
  padding: 0.4em 0.3em;
  justify-content: center;
  align-content: center;
  overflow: auto;
  background-color: #f3f6f9;
  height: 4rem;
  width: fit-content;
}
.infImg {
  height: 1.5rem;
  width: 1.8rem;
  padding: auto;
  margin: auto 0.3rem;
}
.InfCityTxt {
  margin: auto 0px;
  font-weight: bolder;
  font-size: 1rem;
}
.ViewsTxt {
  height: 0.8rem;
  margin: auto 0px;
  /* padding: auto; */
  font-weight: bold;
  font-size: 1rem;
}
.modal-dialog {
  width: 90% !important;
  max-width: 560px;
}
.deleteAll-btn {
  background: #1c97c3 !important;
  float: right !important;
  color: white !important;
  width: 120px !important;
  text-transform: uppercase;
}
::v-deep .deleteAllModal {
  width: 50% !important;
}
::v-deep .view-media-plan-modal {
  max-width: 90% !important;
}
.mdi-plus-bg2,
.close {
  font-size: 14px !important;
}
.socialText,
#input-221,
input {
  font-size: 12px !important;
  height: 10px;
}
::v-deep .v-select__slot,
::v-deep .v-text-field__slot {
  font-size: 12px;
}
::v-deep .countary {
  display: none;
}
::v-deep .code-class {
  background-color: #d2f3f2;
  color: #1c97c3;
  padding: 8px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
}
::v-deep .status-class {
  font-size: 11px;
  font-weight: 600;
  color: #000;
  background: #ddd8d8;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
}
</style>
