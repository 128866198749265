<template>
  <div class="box mt-2">
    <button @click="view" class="fa fa-comment-dots"></button>
    <!-- <p>{{value}}</p> -->
    <!-- :class="[Active ? 'greenParent' : '',Reject ? 'redParent' : '']" -->
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";

export default {
  name: "AdsComments",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    view() {
      console.log(this.params.data.id);
      this.params.context.componentParent.show_comments(this.params.data.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
