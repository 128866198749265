import { render, staticRenderFns } from "./CellRenderDate.vue?vue&type=template&id=37af1904&scoped=true&"
var script = {}
import style0 from "./CellRenderDate.vue?vue&type=style&index=0&id=37af1904&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37af1904",
  null
  
)

export default component.exports