<template>
  <v-app>
    <b-modal
      id="comments_modal"
      @ok="ConfirmComment"
      ok-only
      ok-title="CONFIRM"
      ok-variant="zzzz"
      hide-header
    >
      <div class="m-7">
        <h3 class="CommentsMainHeader">Mega Influencer</h3>
        <span class="comments-date"
          >for 12 October, 2020
          <span class="text-muted mx-5">{{ ClientName }}</span>
        </span>

        <div class="mb-8 mt-5 plan-boxes">
          <div style="display: inline-box" class="boxes_inline mx-2">
            <div class="header_boxes">
              <div class="content-box_comments">
                <span class="box__title">Cost</span>
                <h1 class="box_quantity">AED 2,500</h1>
              </div>
            </div>
          </div>
          <div class="boxes_inline mx-2">
            <div class="header_boxes">
              <div class="content-box_comments">
                <span class="box__title mb-1">Expected Orders</span>
                <h1 class="box_quantity">570,000</h1>
              </div>
            </div>
          </div>
          <div class="boxes_inline mx-2">
            <div class="header_boxes">
              <div class="content-box_comments">
                <span class="box__title">Commission Expected</span>
                <h1 class="box_quantity">9,000</h1>
              </div>
            </div>
          </div>
          <div class="boxes_inline mx-2">
            <div class="header_boxes">
              <div class="content-box_comments">
                <span class="box__title">Profit Expected</span>
                <h1 class="box_quantity">AED 3,500</h1>
              </div>
            </div>
          </div>
          <div class="boxes_inline mx-2">
            <div class="header_boxes">
              <div class="content-box_comments">
                <span class="box__title">ROI Expected</span>
                <h1 class="box_quantity">2.63</h1>
              </div>
            </div>
          </div>
          <div class="boxes_inline mx-2">
            <div class="header_boxes">
              <div class="content-box_comments">
                <span class="box__title">Coupon</span>
                <h1 class="box_quantity">AE471</h1>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="mx-0 px-0"></v-divider>
        <v-list>
          <template class="my-0 py-0" v-for="item in items">
            <v-subheader
              class="my-0 py-0 comments_header mb-2"
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>

            <!-- <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          ></v-divider> -->

            <v-list-item class="mt-0 pt-0 my-auto" v-else :key="item.title">
              <v-list-item-avatar size="33" class="my-auto">
                <v-img :src="item.avatar"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="">
                <v-list-item-title class="px-2 pb-2 my-0">
                  <span class="comments_header">{{ item.title }} </span>
                  <span class="comments_hrs px-1"
                    >3 hours ago</span
                  ></v-list-item-title
                >
                <v-list-item-subtitle
                  class="px-2 py-0 my-0"
                  v-html="item.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-divider class="mx-0 px-0"></v-divider>
        <p class="add_comm">add a comment</p>
        <p class="add_comm_date">for mega influencer oct 4, 2020</p>
        <v-textarea
          filled
          auto-grow
          clearable
          counter
          label="add comment here.."
          rows="2"
          row-height="22"
        ></v-textarea>
      </div>
    </b-modal>
    <ag-grid-vue
      :context="context"
      class="ag-grid-table ag-theme-material table table-responsive"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :defaultColDef="defaultColDef"
      :suppressDragLeaveHidesColumns="true"
      :rowData="AdsListData"
      :animateRows="true"
      rowSelection="multiple"
      @rowSelected="onSelectAd"
    >
    </ag-grid-vue>
  </v-app>
</template>
<script>
import ApiService from "../../core/services/api.service";
import { AgGridVue } from "ag-grid-vue";
import CellRendererDropdown from "@/view/pages/CellRenderDropdown.vue";
import AdsComments from "@/view/components/AdsComments.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";

import CellRenderProfile from "@/view/components/CellRenderProfile";
export default {
  name: "ads",
  props: ["ClientName", "selectedCampaign", "camp", "isInfluencer", "platform_type"],
  components: {
    AgGridVue,
    AdsComments,
    CellRendererDropdown,
    CellRendererAction,
    CellRenderProfile,
  },
  data() {
    return {
      title: "Rick Grimes",
      items: [
        { header: "Comments" },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Shreerag Palakzhi",
          subtitle: `<span class="text--primary"> this guy is too cheap, lets look at more ppl`,
        },

        // {
        //   avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        //   title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        //   subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        // },

        // {
        //   avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        //   title: "Oui oui",
        //   subtitle:
        //     '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        // },

        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Lisa Simpson",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },

        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: " Meow Meow",
          subtitle:
            '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      context: {},
      selectedRows: [],
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
      },
      gridOptions: {
        frameworkComponents: {
          //   CellRendererAvatars: CellRendererAvatars,
          //   CellRendererName: CellRendererName,
          //   CellRendererMATERIALS: CellRendererMATERIALS,
          //   CellRendererCoupon: CellRendererCoupon,
          CellRendererDropdown: CellRendererDropdown,
          //   CellRendererAction: CellRendererAction
        },
        context: {
          componentParent: this,
        },
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 40,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "DATE",
            field: "",
            filter: true,
            cellStyle: { "line-height": "2" },
            cellRenderer: (params) => {
              return `<span  class="">${params.data.planned_date}</span><br/>
              <span class="status-col">${params.data.status}</span>
              `;
            },
          },
          {
            headerName: "INFLUENCER",
            minWidth: 200,
            cellRendererFramework: "CellRenderProfile",
            filter: false,
          },
          {
            headerName: "AUDIENCE",
            minWidth: 150,
            filter: false,
            cellRenderer: (params) => {
              return `<span  class="users">${
                params.data.audience ? params.data.audience : 0
              }
              `;
            },
          },
          {
            headerName: "LOCATION",
            field: "city",
            filter: false,
          },
          {
            headerName: "AD TYPE",
            field: "type",
            filter: false,
          },
          {
            headerName: "COST",
            filter: false,
            cellRenderer: (params) => {
              return `${params.data.cost_currency} ${params.data.cost}`;
            },
          },
          {
            headerName: "EXPECTATIONS",
            minWidth: 140,
            filter: false,
            Width: 10,
            cellRenderer: (params) => {
              return `${params.data.commission_exp_currency} ${params.data.commission_exp}`;
            },
          },
          {
            headerName: "COMMENT",
            Width: 80,
            class: "text-center",
            style: "text-align:center",
            // cellRendererFramework: 'CellRendererAction',
            cellRenderer: (params) => {
              return `<button  class="fa fa-comment-dots"></button>`;
            },
            // cellRendererParams:{
            //     action: doSomeAction.bind(this),

            // },
            suppressSorting: true,
          },
          {
            headerName: "STATUS",
            Width: 80,
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },
          // {
          //   headerName: "COMMENTS",
          //   field: "coupon_id",

          //   filter: false,
          //   cellRenderer: (params) => {
          //     return params.value
          //       ? `<span class="badge badge-pill badge-primary">${params.value}</span>`
          //       : "";
          //   },
          // },
          // {
          //   headerName: "COMMENT",
          //   Width: 30,
          //   minWidth:135,
          //   class: "text-center",
          //   style: "text-align:center",
          //   cellRendererFramework: "AdsComments",
          //   suppressSorting: true,
          // },
          // cellRenderer: (params) => {
          //   return `<b-btn  @click="abc"></b-btn>`;
          // },
          // cellRendererParams:{
          //     action: doSomeAction.bind(this),

          // },
        ],
      },
      AdsListData: [],
      allocation: [
        {
          id: 0,
          date: "Oct 4, 2020",
          type: "Mega Influencer",
          cost: "AED 1960",
          expected_orders: "10000",
          commission: "AED 8960",
          profit: "AED 8960",
          roi: "2.1",
          coupon: "1 AED",
          status: "active",
        },
        {
          id: 0,
          date: "Oct 4, 2020",
          type: "Mega Influencer",
          cost: "AED 1960",
          expected_orders: "10000",
          commission: "AED 8960",
          profit: "AED 8960",
          roi: "2.1",
          coupon: null,
          status: "active",
        },
        {
          id: 0,
          date: "Oct 4, 2020",
          type: "Photo Ads",
          cost: "AED 1960",
          expected_orders: "10000",
          commission: "AED 8960",
          profit: "AED 8960",
          roi: "2.1",
          coupon: "3 AED",
          status: "active",
        },
        {
          id: 0,
          date: "Oct 6, 2020",
          type: "Mega Influencer",
          cost: "AED 1960",
          expected_orders: "10000",
          commission: "AED 8960",
          profit: "AED 8960",
          roi: "2.1",
          coupon: "2 AED",
          status: "active",
        },
      ],
    };
  },
  beforeMount() {
    this.context = {
      componentParent: this,
    };
  },
  mounted() {
    this.listCampaignAds();
  },
  methods: {
    listCampaignAds() {
      var camp = [];
      for (var i = 0; i < this.selectedCampaign.length; i++) {
        camp.push(this.selectedCampaign[i]._id);
      }
      const params = {
        campaigns: camp,
        platform_type: this.platform_type,
        sort: "-profit_exp",
      };
      ApiService.post(`ads/ads-by-campaigns`, params).then((response) => {
        this.AdsListData = response.data.data.data;
        console.log(this.AdsListData);
      });
    },
    onSelectAd() {
      this.$store.dispatch("INF_ADS_ACTION", this.selectedRows);
      this.selectedRows = this.gridApi.getSelectedNodes();
      this.$emit("changeTitle", this.selectedRows.length);
    },
    ConfirmComment() {
      console.log("sss");
    },
    show_comments(nodeData) {
      this.$bvModal.show("comments_modal");
      console.log(nodeData);
    },
    onGridReady(params) {
      console.log(params);
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    // onSelectCampaign(campaign) {
    //   if (!campaign.node.selected) {
    //     this.selectedCampaign = null;
    //     return;
    //   }
    //   this.selectedCampaign = this.campaigns.find(
    //     (x) => x.id === campaign.rowIndex
    //   );
    // },
  },
};
</script>

<style>
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
.v-list--three-line .v-list-item .v-list-item__content,
.v-list-item--three-line .v-list-item__content {
  align-self: auto;
}
.CommentsMainHeader {
  font-weight: bolder;
  color: black;
  font-size: 2rem;
  letter-spacing: -0.8px;
}
.comments_header {
  height: 1.5rem;
  font-weight: bolder;
  color: black !important;
  font-size: 1rem;
}
.comments_hrs {
  font-size: 0.8rem;
}
.header_boxes {
  background-color: #f1f1f1;
  height: 5.9rem;
  width: fit-content;
  margin: 0px;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: left;
  border-radius: 10px;
}
.content-box {
  font-size: 1rem;
}
.boxes_inline {
  display: inline-block !important;
  margin: 1rem;
}
.content-box_comments {
  text-align: left;
}
.box_quantity {
  font-size: 1.7rem;
  font-weight: bolder;
  color: black;
  margin-top: 0.2rem;
}
.box__title {
  color: black;
  font-weight: bold;
}
.comments-date {
  font-size: 1.1rem !important;
  font-weight: 600;
  color: #f87e79;
}
.add_comm {
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: -1.2px;
  color: black;
}
.add_comm_date {
  font-size: 1.3rem;
  font-weight: bolder;
  color: black;
  letter-spacing: -0.5px;
}
/* .v-input__slot::before {
  border-style: none !important;
} */
.btn-zzzz {
  color: white;
  background-color: #1374d6;
}
.btn-zzzz:hover {
  color: white;
}
</style>
