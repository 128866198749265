<template>
<div style="text-align: center;">
    <div class="row">
        <div class="col-xl-12">
            <img v-if="params.value.src.length > 0" :src="params.value.src" class="table-img " alt="" /><br>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'CellRendererLink',
    methods: {
        statee(sta) {
            if (sta == 'Regular') {
                return "#FFF0D9";
            } else if (sta == 'New') {
                return "#C9BF18";
            } else if (sta == 'Contacted') {
                return "#C8FFBA";
            } else if (sta == 'Standby') {
                return "#E2060B";
            }
        },
    },
    computed: {
        url() {
            return '/myitems/edit/' + this.params.data.id

            // Below line will be for actual product
            // Currently it's commented due to demo purpose - Above url is for demo purpose
            // return "/apps/user/user-view/" + this.params.data.id
        }
    }
}
</script>

<style lang="scss" scoped>
.table-img {
    height: 40px;
    width: 40px;
    border-radius: 5px;
}

.h-10 {
    height: 60px;
}

.state-round {
    font-weight: bold;
    color: #000;
    padding:5px;
    border-radius: 5px;
    // margin-left: -20px;

    .round {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        line-height: 5px;
        background-color: #DAF107;
        display: inline-block;
        margin-right: 5px;
    }
}
</style>
