<template>
  <div class="box">
    <select @change="onChange($event)">
      <option :selected="New" value="New" class="">
        New
      </option>
      <!-- <option
        v-if="
          params.data.status == 'Pause' ||
            params.data.previous_status == 'Cancelled'
        "
        :selected="New"
        value="New"
        class=""
      >
        New
      </option> -->
      <option :selected="Pause" value="Pause" class="">Pause</option>
      <option v-if="Planning" :selected="Planning" value="Planning" class="">
        Planning
      </option>
      <option
        v-if="ReadyForReview"
        :selected="ReadyForReview"
        value="Ready For Review"
      >
        Ready For Review
      </option>
      <option v-if="Approved" :selected="Approved" value="Approved">
        Approved
      </option>
      <option v-if="Edited" :selected="Edited" value="Edited">Edited</option>
      <option v-if="Live" :selected="Live" value="Live">Live</option>
      <option v-if="Completed" :selected="Completed" value="Completed">
        Completed
      </option>

      <option
        v-if="params.data.status == 'Queued'"
        :selected="Queued"
        value="Queued"
        class=""
      >
        Queued
      </option>

      <!-- if campaign is pause, we can see previous status to go back -->
      <!-- <option v-if="params.data.status === 'Pause'">
        {{ params.data.previous_status }}
      </option> -->

      <option :selected="Cancelled" value="Cancelled" class="">
        Cancelled
      </option>
    </select>
    <div
      v-if="params.data.status == 'New' || params.data.status == 'new'"
      class="send_icon"
    >
      <inline-svg
        src="media/svg/icons/Files/icon_share.svg"
        @click="statusQueued"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";

export default {
  name: "CellRenderer",
  data() {
    return {
      Active: false,
      Reject: false,
      Pause: false,
      New: false,
      Cancelled: false,
      Queued: false,
      Planning: false,
      ReadyForReview: false,
      Approved: false,
      Edited: false,
      Live: false,
      Completed: false,
      params: null,
    };
  },
  computed: {},
  mounted() {
    this.value();
  },
  methods: {
    async statusQueued() {
      console.log("this.params", this.params);
      const APIURL = "/allocations/status";

      await ApiService.post(APIURL, {
        client_id: this.$route.params.id,
        campaign_id: this.params.data.campaign_id,
        allocation_id: this.params.data._id,
        status: "Queued",
      });

      this.$bvToast.toast(`Campaign has been sent for planning.`, {
        title: "Status Changed",
        autoHideDelay: 8000,
        toaster: "b-toaster-top-center",
        variant: "success",
        appendToast: false,
      });
      window.location.reload();
      this.params.context.parent.fetchData();
    },
    onChange(event, append = false) {
      console.log("this.params", this.params);
      let APIURL = "/allocations/status";
      let dataToPost = [];
      let dataTest = {
        client_id: this.$route.params.id,
        campaign_id: this.params.data.campaign_id,
        allocation_id: this.params.data._id,
        status: event.target.value,
      };
      dataToPost.push(dataTest);
      dataToPost = JSON.stringify(dataToPost);
      console.log(dataToPost);

      // *******change request if Influencer-Management Page

      if (this.$route.fullPath.startsWith("/performance")) {
        APIURL = "/allocations/status";
        let zz = [];

        (dataTest = {
          client_id: this.$route.params.id,
          campaign_id: this.params.data.campaign_id,
          allocation_id: this.params.data._id,
          status: event.target.value,
        }),
          zz.push(dataTest);
        ApiService.post(APIURL, dataTest).then((response) => {
          console.log("response ", response.data.message);
          this.$bvToast.toast(
            `Status Updated Successfully To ${event.target.value} `,
            {
              title: "Status Changed",
              autoHideDelay: 5000,
              toaster: "b-toaster-top-center",
              variant: "success",
              appendToast: append,
            }
          );

          this.params.context.parent.fetchData();
          window.location.reload();
        });
        // console.log(APIURL);
        // console.log(this.params.data);
      }

      if (event.target.value == "New" || event.target.value == "new") {
        this.New = true;
      } else {
        this.New = false;
      }
    },

    value() {
      if (this.params.data.status == "Pause") {
        this.Pause = true;
      } else if (this.params.data.status == "Queued") {
        this.Queued = true;
      } else if (this.params.data.status == "Active") {
        this.Active = true;
      } else if (
        this.params.data.status == "New" ||
        this.params.data.status == "new"
      ) {
        this.New = true;
      } else if (this.params.data.status == "Cancelled") {
        this.Cancelled = true;
      } else if (this.params.data.status == "Planning") {
        this.Planning = true;
      } else if (this.params.data.status == "Ready For Review") {
        this.ReadyForReview = true;
      } else if (this.params.data.status == "Approved") {
        this.Approved = true;
      } else if (this.params.data.status == "Edited") {
        this.Edited = true;
      } else if (this.params.data.status == "Live") {
        this.Live = true;
      } else if (this.params.data.status == "Completed") {
        this.Completed = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  font-size: inherit;
  background: rgb(89, 158, 89) !important;
  color: white !important;
}
.red {
  font-size: inherit;
  background: #fd397a !important;
  color: white !important;
}
.yellow {
  font-size: inherit;
  background: #f3a430 !important;
  color: white !important;
}
.blue {
  font-size: inherit;
  background: rgb(163, 163, 255) !important;
  color: white;
}
.box select option:focus {
  background: #aaa !important;
}
.box {
  border-radius: 5%;
  padding: 0px;
  margin: 0px;
}

.box select {
  font-size: 1rem !important;
  text-align: center;
  border: 0.1px solid #c3dcf5;
  background-color: #f3f9ff;
  border-radius: 0.5rem;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  line-height: 2.5;
  width: 88px;
}
.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}
.send_icon {
  position: absolute;
  margin: auto;
  height: 3rem;
  width: 3rem;
  margin-left: 0.2rem;
  text-align: center;
  display: inline-block;
}
svg {
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
}
.send_icon:hover {
  transform: scale(1.2);
}
</style>
