<template>
  <v-app>
    <div class="create-campaign">
      <b-row>
        <b-col>
          <span class="main-heading"
            >{{ type == "edit" ? "Edit" : "Create" }} Performance Campaign</span
          >
          <br />
          <span class="sub-heading">for {{ name }}</span>
        </b-col>
        <b-col
          cols="auto"
          role="button"
          @click="
            $bvModal.hide(
              `${type == 'edit' ? 'edit' : 'create'}-campaign-modal`
            )
          "
          class="close"
        >
          close
        </b-col>
      </b-row>

      <form ref="form" class="form-data">
        <!-- ADD DATE -->
        <b-form-group id="fieldset" label="Add Date" label-for="add-date">
          <b-row id="add-date" style="margin-top: -10px">
            <b-col class="pb-0 pt-0">
              <b-form-group
                id="fieldset-start-date"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                label="from"
                label-for="start-date"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="startDate"
                  @context="onSelectStartDate"
                  :disabled="isFullMonthCampaign"
                  placeholder="1/10/2020"
                  locale="en"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col class="pb-0 pt-0">
              <b-form-group
                id="fieldset-end-date"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                label="to"
                label-for="end-date"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="endDate"
                  :disabled="isFullMonthCampaign"
                  @context="onSelectEndDate"
                  placeholder="31/10/2020"
                  locale="en"
                  :min="min"
                  :max="max"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="startDate">
            <b-col class="pt-0">
              <b-form-checkbox
                class="custom-control custom-checkbox checkbox-xl"
                v-model="isFullMonthCampaign"
                @change="toggleFullMonth"
              >
                <span class="labels campaign-label">
                  FULL MONTH CAMPAIGN
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- ADD NAME -->
        <b-form-group
          id="name-fieldset"
          class="input-label mt-5"
          label="Add Campaign Name"
          label-for="compaignName"
        >
          <b-form-input
            id="compaignName"
            v-model="name"
            class="input-field custom-input"
            type="text"
            required
            :placeholder="`${ClientName}, October 2020 Campaign`"
          />
        </b-form-group>

        <!-- ADD BUDGET -->
        <b-form-group
          id="budget-fieldset"
          class="input-label mt-5 col-12 px-0"
          label="Add Budget"
          label-for="budget"
        >
          <b-input-group class="mt-3">
            <template #prepend>
              <b-dropdown :text="budget_currency" class="currency-dropdown">
                <b-dropdown-item @click="budget_currency = 'AED'"
                  >AED</b-dropdown-item
                >
                <b-dropdown-item @click="budget_currency = 'USD'"
                  >USD</b-dropdown-item
                >
                <b-dropdown-item @click="budget_currency = 'SAR'"
                  >SAR</b-dropdown-item
                >
                <b-dropdown-item @click="target.revenue_currency = 'KWD'"
                  >KWD</b-dropdown-item
                >
              </b-dropdown>
            </template>

            <b-form-input type="number" v-model="budget"></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- ADD TARGET -->
        <b-form-group class="input-label mt-5 col-12 pl-0">
          <label>Add Target </label>

          <PerfCampaignDropdown
            class="target-type-dropdown"
            @targetType="setTargetType"
          ></PerfCampaignDropdown>

          <b-row>
            <!-- Target -->
            <!-- <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">TARGET </label>
                <b-input-group type="number">
                  <template #prepend>
                    <b-dropdown
                      :text="target.target_currency"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item @click="target.target_currency = 'AED'"
                        >AED</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.target_currency = 'USD'"
                        >USD</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.target_currency = 'SAR'"
                        >SAR</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>

                  <b-form-input
                    type="number"
                    v-model="target.target"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col> -->
            <!-- Target -->
            <!-- Revenu -->
            <b-col
              class="pb-0 col-6 col-md-6"
              v-if="tempTargertType == 'revenue'"
            >
              <b-form-group class="input-label">
                <label class="sub-label">REVENUE </label>
                <b-input-group type="number">
                  <template #prepend>
                    <b-dropdown
                      :text="target.revenue_currency"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item @click="target.revenue_currency = 'AED'"
                        >AED</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.revenue_currency = 'USD'"
                        >USD</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.revenue_currency = 'SAR'"
                        >SAR</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.revenue_currency = 'KWD'"
                        >KWD</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>

                  <b-form-input
                    type="number"
                    v-model="target.revenue"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">REVENUE </label>
                <b-form-input
                  id="revenue"
                  v-model=""
                  class="input-field custom-input"
                  type="text"
                  placeholder="AED 250,000"
                />
              </b-form-group>
            </b-col> -->
            <!-- Revenu -->
            <!-- ROI -->
            <b-col class="pb-0 col-6 col-md-6" v-if="tempTargertType == 'roi'">
              <b-form-group class="input-label">
                <label class="sub-label">ROI </label>
                <b-form-input
                  id="roi"
                  v-model="target.roi"
                  class="input-field custom-input"
                  type="number"
                  placeholder="3.1"
                />
              </b-form-group>
            </b-col>
            <!-- ROI -->
            <!-- ORDERS -->
            <b-col
              class="pb-0 col-6 col-md-6"
              v-if="tempTargertType == 'orders'"
            >
              <b-form-group class="input-label">
                <label class="sub-label">ORDERS </label>
                <b-form-input
                  id="orders"
                  v-model="target.orders"
                  class="input-field custom-input"
                  type="number"
                  placeholder="120,000"
                />
              </b-form-group>
            </b-col>
            <!-- ORDERS -->
            <!-- COMMISSION -->
            <b-col
              class="pb-0 col-6 col-md-6"
              v-if="tempTargertType == 'commission'"
            >
              <b-form-group class="input-label">
                <label class="sub-label">COMMISSION </label>
                <b-input-group type="number">
                  <template #prepend>
                    <b-dropdown
                      :text="target.commission_currency"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item
                        @click="target.commission_currency = 'AED'"
                        >AED</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="target.commission_currency = 'USD'"
                        >USD</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="target.commission_currency = 'SAR'"
                        >SAR</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="target.commission_currency = 'KWD'"
                        >KWD</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>

                  <b-form-input
                    type="number"
                    v-model="target.commission"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- OFFERS -->
        <b-form-group class="input-label mt-5 col-12 pl-0">
          <label
            >Add Offers
            <v-btn
              @click="toggleOffersOption"
              class="mx-1 ml-4 mr-4 mdi-plus-bg2"
              fab
              dark
              color="blue"
            >
              <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
            </v-btn>
          </label>
          <v-row v-if="showOffersSection">
            <v-col cols="12">
              <v-select
                deletable-chips
                color="#00a4f3"
                clearable
                background-color="#f3fcff"
                :menu-props="{ bottom: true, offsetY: true }"
                hide-selected
                multiple
                attach
                outlined
                v-model="SelectedOffer"
                item-text="title"
                item-value="_id"
                :items="offers"
                label="Select Offers"
                :persistent-hint="true"
                hint="Select Offer or create a new one by typing and hitting Enter"
                chips
              ></v-select>
            </v-col>
          </v-row>
        </b-form-group>

        <!-- ADD SPECIAL PAYOUT -->
        <b-form-group class="input-label mt-15 col-12 pl-0">
          <label
            ><b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              v-model="is_special_payout"
            >
              Special Payout
            </b-form-checkbox>
          </label>
          <b-input-group v-if="is_special_payout" class="mt-3">
            <template #prepend v-if="payout_type === 'CPO'">
              <b-dropdown :text="payout_currency" class="currency-dropdown">
                <b-dropdown-item @click="payout_currency = 'AED'"
                  >AED</b-dropdown-item
                >
                <b-dropdown-item @click="payout_currency = 'USD'"
                  >USD</b-dropdown-item
                >
                <b-dropdown-item @click="payout_currency = 'KWD'"
                  >KWD</b-dropdown-item
                >
                <b-dropdown-item @click="payout_currency = 'SAR'"
                  >SAR</b-dropdown-item
                >
              </b-dropdown>
            </template>
            <template #prepend v-else>
              <b-dropdown :text="'%'" class="currency-dropdown">
                <b-dropdown-item>%</b-dropdown-item>
              </b-dropdown>
            </template>

            <b-form-input type="number" v-model="payout"></b-form-input>

            <template #append>
              <b-dropdown :text="payout_type" class="payout-dropdown">
                <b-dropdown-item @click="payout_type = 'CPO'"
                  >CPO</b-dropdown-item
                >
                <b-dropdown-item @click="payout_type = 'CPS'"
                  >CPS</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-input-group>
        </b-form-group>

        <!-- ADD FOCUS ON PRODUCTS -->
        <b-form-group class="input-label mt-5 col-12 pl-0">
          <label
            ><b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              v-model="focusOnProducts"
            >
              Focus on Products
            </b-form-checkbox>
          </label>
          <v-combobox
            v-if="focusOnProducts"
            id="products"
            v-model="products"
            clearable
            hide-selected
            multiple
            small-chips
            hint="Press enter to add a product"
            persistent-hint
            deletable-chips
            placeholder="Sliders, Sneakers, Boots"
          ></v-combobox>
        </b-form-group>

        <!-- ADD FOCUS ON GEO -->
        <b-form-group class="input-label mt-5 col-12 pl-0">
          <label
            ><b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              v-model="focusOnGeo"
            >
              Focus on GEO
            </b-form-checkbox>
          </label>
          <v-combobox
            v-if="focusOnGeo"
            id="geo"
            v-model="geos"
            clearable
            hide-selected
            multiple
            small-chips
            hint="Press enter to add a geo"
            persistent-hint
            placeholder="Dubai, Dammam"
            deletable-chips
          ></v-combobox>
        </b-form-group>
        <!-- ADD BUTTON -->
        <b-form-group class="mt-5">
          <b-button
            :disabled="!isFormValidated || isLoading"
            class="add-btn"
            @click="handleSubmit"
            >{{ type == "edit" ? "Edit" : "Add" }}
            <b-spinner v-if="isLoading" small type="grow"></b-spinner>
          </b-button>
        </b-form-group>
      </form>
    </div>
  </v-app>
</template>
<style lang="scss" scoped>
.create-campaign {
  .main-heading {
    font-weight: bold;
    font-size: 20px !important;
    color: #000000;
  }
  .sub-heading {
    font-size: 15px !important;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .form-data {
    margin-top: 10px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px !important;
      font-size: 13px;
    }
    input {
      height: 50px !important;
      background: #f3fcff 0% 0% no-repeat padding-box;
      opacity: 1;
      font-size: 13px;
      color: #000000;
    }
    label {
      align-self: center;
      font-size: 14px;
      color: #000000;
    }
    .sub-label {
      font-size: 12px !important;
      font-weight: bold !important;
    }
    #checkbox-1::after {
      width: 29px;
      height: 27px;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }
    .custom-control-input {
      display: none;
    }

    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 29px;
      height: 27px;
      border-radius: 8px;
      opacity: 1;
    }

    .checkbox-xl .custom-control-label {
      padding-left: 20px;
      padding-top: 5px;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 12px/25px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 150px;
      height: 50px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .payout-dropdown {
      button,
      button:focus {
        background: black;
        color: #fff;
        font-size: 14px;
        font: normal normal bold 12px/25px Poppins;
      }
      button::after {
        font-size: 12px;
        color: #fff;
      }
    }
    .currency-dropdown {
      button,
      button:focus {
        background: #f3fcff;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
      button::after {
        font-size: 12px;
        color: #000000;
      }
    }
    .offer-sec {
      height: 58px;
      background: #000000 0% 0% no-repeat padding-box;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      border-radius: 8px;
      line-height: 55px;
    }
    .theme--light.v-chip {
      margin-top: 0.5rem;
      height: 2rem;
      background: #000000 0% 0% no-repeat padding-box;
      font-size: 1.2rem;
      color: #ffffff;
      text-align: center;
      border-radius: 8px;
    }
    .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
      background-color: #f3fcff;
      margin-top: 0.5rem;
    }
    .v-input input,
    .v-input textarea:focus,
    .v-input textarea:active {
      background-color: transparent;
      margin-top: 0.5rem;
    }
    .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 50px !important;
    }
    .v-chip .v-chip__close.v-icon.v-icon--right {
      margin-right: -4px;
      color: white !important;
    }
    .v-date-picker-table .v-btn.v-btn--active {
      color: black !important;
      // background-color: blue;
    }
  }
}
</style>

<script>
import ToasterService from "../../../../core/services/toaster.service";
import ApiService from "../../../../core/services/api.service";
import PerfCampaignDropdown from "@/view/content/dropdown/PerfCampTargetDropdown.vue";

export default {
  name: "create-campaign",
  props: ["ClientName", "type", "campaignData", "calenderDate"],
  components: {
    PerfCampaignDropdown,
  },
  data: () => ({
    client_id: null,
    startDate: "",
    endDate: "",
    items: [
      "Black Friday 15% off",
      "Back to School 50% off",
      "Cyber Monday 15% off",
    ],
    SelectedOffer: [],
    isFullMonthCampaign: false,
    budget_currency: "AED",
    name: "",
    budget: "",
    target: {
      target: null,
      revenue: null,
      roi: null,
      orders: null,
      commission: null,
      target_currency: "AED",
      revenue_currency: "AED",
      commission_currency: "AED",
    },

    payout_currency: "AED",
    payout: "",
    payout_type: "CPO",
    coupons: [],
    products: [],
    geos: [],
    couponsArray: [],
    min: null,
    max: null,
    firstDay: null,
    lastDay: null,
    showTarget: false,
    is_special_payout: false,
    showOffersSection: false,
    focusOnProducts: false,
    focusOnGeo: false,
    isLoading: false,
    offers: [],
    tempTargertType: "",
    show_calendar: false,
  }),
  mounted() {
    this.client_id = this.$route.params.id;
    this.fetchOffers();
    if (this.type == "edit") {
      ApiService.post("/campaigns/detail", {
        client_id: this.campaignData.client_id,
        campaign_id: this.campaignData._id,
      }).then((x) => {
        let resp = x.data.data[0];
        console.log(resp);
        console.log(this.campaignData);
        this.client_id = resp.client_id;
        this.name = resp.title;
        this.budget = resp.budget;
        this.budget_currency = resp.budget_currency;
        this.target.target = resp.target;
        this.target.target_currency = resp.target_currency;
        this.target.commission = resp.commission;
        this.target.commission_currency = resp.commission_currency;
        this.target.revenue = resp.revenue;
        this.target.revenue_currency = resp.revenue_currency;
        this.target.roi = resp.roi;
        this.target.orders = resp.orders;
        this.startDate = resp.start;
        this.endDate = resp.end;
        this.products = resp.products;
        this.geos = resp.geo;
        this.is_special_payout = resp.is_special_payout;
        this.payout_currency = resp.payout_currency;
        this.payout = resp.payout;
        this.payout_type = resp.payout_type;
        this.showTarget = resp.add_target;
        this.showOffersSection = resp.add_offers;
        this.focusOnGeo = resp.focus_on_geo;
        this.focusOnProducts = resp.focus_on_product;
        this.tempTargertType = resp.target_type;
        this.SelectedOffer = this.offers.filter((x) =>
          resp.offers.includes(x._id)
        );
      });
      this.setTargetType(this.tempTargertType);
    }
  },
  methods: {
    toggleOffersOption() {
      this.showOffersSection = !this.showOffersSection;
    },
    setTargetType(val) {
      console.log(val);
      this.tempTargertType = val;
      console.log(this.tempTargertType);
    },
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.firstDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth(),
        1
      );
      this.lastDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth() + 1,
        0
      );

      // set date min and max range
      this.min = new Date(this.firstDay);
      this.min.setDate(ctx.activeDate.getDate());
      this.max = new Date(this.lastDay);

      // auto fill campaign name
      // if (this.type !== "edit") {
      //   this.name = this.getAutoCampaignName();
      // }
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      if (!this.endDate || this.isFullMonthCampaign) {
        return;
      }
      this.endCompleteDate = ctx.activeDate;
      // auto fill campaign name
      // if (this.type !== "edit") {
      //   this.name = this.getAutoCampaignName();
      // }
    },

    /* toggle full month campaign  */
    toggleFullMonth(val) {
      // set month start and end date if user select full month campaign check.
      if (val) {
        this.startDate = this.toJSONLocal(this.firstDay);
        this.endDate = this.toJSONLocal(this.lastDay);
      }
      // auto fill campaign name
      // this.name = this.getAutoCampaignName();
    },
    toJSONLocal(date) {
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },

    /* get formatted auto campaign name according to dates. */
    getAutoCampaignName() {
      // let month = this.startCompleteDate.toLocaleString("default", {
      //   month: "long",
      // });
      // let dateRange =
      //   this.isFullMonthCampaign || !this.endCompleteDate
      //     ? ""
      //     : `${this.startCompleteDate.getDate()} - ${this.endCompleteDate.getDate()}`;
      // let year = this.startCompleteDate.getFullYear();
      // return ` ${this.ClientName}, ${dateRange} ${month} ${year} Campaign`;
      return this.ClientName;
    },

    toggleTargetOption() {
      this.showTarget = !this.showTarget;
    },
    toggleOffersOption() {
      this.showOffersSection = !this.showOffersSection;
    },
    fetchOffers() {
      ApiService.get(`/offers?client_id=${this.$route.params.id}`).then(
        (response) => {
          this.offers = response.data.data.data;
          console.log("offers", this.offers);
        }
      );
    },

    handleSubmit() {
      let dataToPost = {
        client_id: this.client_id,
        title: this.name,
        description: "this is description from web",
        status: "New",
        budget: this.budget,
        budget_currency: this.budget_currency,
        target: this.target.target,
        target_currency: this.target.target_currency,
        commission: this.target.commission,
        commission_currency: this.target.commission_currency,
        revenue: this.target.revenue,
        revenue_currency: this.target.revenue_currency,
        roi: this.target.roi,
        orders: this.target.orders,
        start: this.startDate,
        end: this.endDate,
        platform_type: "PERFORMANCE",
        products: this.products,
        geo: this.geos,
        add_target: this.showTarget,
        add_offers: this.showOffersSection,
        focus_on_geo: this.focusOnGeo,
        focus_on_product: this.focusOnProducts,
        coupons: this.coupons,
        offers: this.SelectedOffer,
        is_special_payout: this.is_special_payout,
        payout_currency: this.payout_currency,
        payout: this.payout,
        payout_type: this.payout_type,
        target_type: this.tempTargertType,
      };
      this.isLoading = true;
      if (this.type == "edit") {
        ApiService.post("/campaigns/update", {
          ...dataToPost,
          campaign_id: this.campaignData._id,
        }).then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$router.push({ name: "performancehome" });
          this.$bvModal.hide("edit-campaign-modal");
          location.reload();
          this.$emit("refresh", response.data.data.message);
          // this.$store.dispatch("ALL_CAMPAIGN_DATA", this.client_id);
          this.$store.dispatch("ALL_CAMPAIGN_DATA", {
            year: this.calenderDate.year,
            month: this.calenderDate.month,
            client_id: this.client_id,
          });
        });
      } else {
        ApiService.post("/campaigns/create", dataToPost).then((response) => {
          console.log("response ", response.data.message);
          if (response.data.message[0] === "Title already exists") {
            ToasterService.makeToast(
              this,
              `${
                response.data.message[0] === "Title already exists"
                  ? "Error"
                  : "Success"
              }`,
              response.data.message[0],
              `${
                response.data.message[0] == "Title already exists"
                  ? "danger"
                  : "Error"
              }`
            );
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          this.$router.push({ name: "performancehome" });

          this.$bvModal.hide("create-campaign-modal");
          
          location.reload();
          this.$emit("refresh", response.data.data.message);
          // this.$store.dispatch("ALL_CAMPAIGN_DATA", this.client_id);
          this.$store.dispatch("ALL_CAMPAIGN_DATA", {
            year: this.calenderDate.year,
            month: this.calenderDate.month,
            client_id: this.client_id,
          });
        });
      }
    },
  },
  watch: {
    type() {
      console.log("changed type", this.type);
    },
  },
  computed: {
    isFormValidated() {
      // if (
      //   this.showTarget && !( this.target.revenue ||  this.target.orders ||    this.target.roi || this.target.commission ) &&!(this.startDate)
      // ) {
      //   return false;
      // }

      // return true;
      if (this.name && this.startDate && this.endDate && this.budget) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
#budget-fieldset .input-group {
  width: 20rem !important;
}
.target-type-dropdown {
  display: inline-flex;
  border: none;
  vertical-align: middle;
  margin-left: 10px;
}
</style>
