<template>
  <div>
    <!-- Edit Modal Component -->

    <!-- edit allocation modal -->
    <b-modal
      id="edit-allocation-modal"
      title="Overflowing Content"
      hide-header
      hide-footer
    >
      <edit-allocation
        type="edit"
        :campaignId="selected_camp[0]._id"
        :ClientName="selected_camp[0].client_name"
        :row="edit_row"
        v-if="edit_row"
        @updated="fetchData"
      />
    </b-modal>
    <ag-grid-vue
      class="ag-grid-table ag-theme-material table table-responsive"
      :context="context"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :suppressDragLeaveHidesColumns="true"
      :defaultColDef="defaultColDef"
      :rowData="allocation"
      :animateRows="true"
      rowSelection="single"
      @rowSelected="onSelectCampaign"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "../../core/services/api.service";
import { AgGridVue } from "ag-grid-vue";
import CellRendererDropdown from "@/view/pages/CellRenderDropdown.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";
import editAllocation from "../pages/performance_management/allocations/create-allocation.vue";
import editCampaign from "../pages/performance_management/campaigns/Edit_Campaign.vue";
import CellRenderPeriod from "@/view/pages/CellRenderDate.vue";

export default {
  name: "allocation",
  components: {
    AgGridVue,
    CellRendererDropdown,
    CellRendererAction,
    editAllocation,
    editCampaign,
    CellRenderPeriod
  },
  data() {
    return {
      context: {},
      editable_row: null,
      selected_camp: this.$store.getters.CampaignGetters,
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 120,
        filter: true,
        resizable: true,
      },
      gridOptions: {
        frameworkComponents: {
          //   CellRendererAvatars: CellRendererAvatars,
          //   CellRendererName: CellRendererName,
          //   CellRendererMATERIALS: CellRendererMATERIALS,
          //   CellRendererCoupon: CellRendererCoupon,
          //   CellRendererDropdown: CellRendererDropdown,
          //   CellRendererAction: CellRendererAction
        },
        rowHeight: 60,
        columnDefs: [
          {
            maxWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "ALLOCATION",
            minWidth: 130,
            field: "title",
            filter: true,
          },
          {
            headerName: "CAMPAIGN",
            field: "campaign_name",
            filter: true,
          },
          {
            headerName: "PERIOD",
            filter: true,
            cellRendererFramework: "CellRenderPeriod",

          },
          {
            headerName: "BUDGET",
            field: "budget",
            filter: false,
            valueGetter(params) {
              return `${params.data.budget} ${params.data.budget_currency}`;
            },
          },
          {
            headerName: "TARGET",
            field: "target",
            filter: false,
            valueGetter(params) {
              return `${params.data.target} ${params.data.target_sales_currency}`;
            },
          },
          {
            headerName: "ACTUAL COST",
            field: "n_actual_cost",
            minWidth: 135,
            filter: false,
             valueGetter(params) {
              return `${params.data.n_actual_cost} ${params.data.actual_cost_currency}`;
            },
          },
          
          {
            headerName: "COMMISSION",
            field: "n_commission",
            minWidth: 135,
            filter: false,
             valueGetter(params) {
              return `${params.data.n_commission} ${params.data.commission_currency}`;
            },
          },
          {
            headerName: "PROFIT",
            field: "n_profit",
            filter: false,
             valueGetter(params) {
              return `${params.data.n_profit} ${params.data.profit_currency}`;
            },
          },
          {
            headerName: "STATUS",
            minWidth: 150,
            cellRendererFramework: "CellRendererDropdown",
            suppressSorting: true,
          },
          {
            headerName: "ACTION",
            Width: 80,
            cellRendererFramework: "CellRendererAction",
            suppressSorting: true,
          },
        ],
      },
      allocation: [],
    };
  },
  computed: {
    //   ...mapGetters(['CampaignGetters']),
    // selectedCampaign(){
    //     return this.$store.getters.CampaignGetters;
    //   },
    edit_row: {
      get() {
        return this.editable_row;
      },
      set() {
        return this.editable_row;
      },
    },
  },
  beforeMount() {
    this.context = {
      parent: this,
      viewable: false,
      editable: true,
      deleteable: true,
    };
  },
  updated() {
    // console.log("Update Cycle of allocation ")
    // this.fetchData();
  },
  methods: {
    onGridReady(params) {
      this.fetchData();
    },
    fetchData() {
      var allAllocation = [];
      for (let j = 0; j < this.selected_camp.length; j++) {
        let dataToPost = {
          client_id: this.$route.params.id,
          campaign_id: this.selected_camp[j]._id,
        };
        ApiService.post("/allocations/list", dataToPost).then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            response.data.data[i].campaign_name = this.selected_camp[j].title
            response.data.data[i].campaign_id = this.selected_camp[j]._id
            response.data.data[i].n_actual_cost = this.selected_camp[j].actual_cost
            response.data.data[i].n_commission = this.selected_camp[j].commission
            response.data.data[i].n_profit = this.selected_camp[j].profit
            allAllocation.push(response.data.data[i]);
          }
          // this.allocation = response.data.data;
          for (let i = 0; i < this.allocation.length; i++) {
            console.log("parm", this.allocation[i])
            this.allocation[i].campaign_title = this.selected_camp[j].title;
          }
        });
      }
      this.allocation = allAllocation;
    },
    editGridAction(data) {
      console.log("edit allocation");
      this.editable_row = data;
      this.$bvModal.show("edit-allocation-modal");
    },
    deleteGridAction(data) {
      let dataToPost = {
        client_id: this.$route.params.id,
        campaign_id: this.selected_camp[0]._id,
        allocation_id: data._id,
      };
      ApiService.post("/allocations/delete", dataToPost).then((response) => {
        this.fetchData();
      });
    },
    onSelectCampaign(campaign) {
      // if (!campaign.node.selected) {
      //   this.selectedCampaign = null;
      //   return;
      // }
      // this.selectedCampaign = this.campaigns.find(
      //   (x) => x.id === campaign.rowIndex
      // );
    },
  },
};
</script>
<style scoped></style>
