<template>
<div style="text-align: center;    margin-top: 5px;">
    <div class="row">
        <div class="col-xl-6" style="display: flex;align-items: center;">
            <h6 style="font-weight:bold;    margin-left: -1rem;">{{params.value.name}} <span class="state-round" :style="'background-color:'+ statee(params.value.state) ">{{ params.value.state }}</span></h6>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'CellRendererLink',
    methods: {
        statee(sta) {
            if (sta == 'Regular') {
                return "#FFF0D9";
            } else if (sta == 'New') {
                return "#FFFCBF";
            } else if (sta == 'Contacted') {
                return "#C8FFBA";
            } else if (sta == 'Standby') {
                return "#E2060B";
            }
        },
    },
    computed: {
        url() {
            return '/myitems/edit/' + this.params.data.id

            // Below line will be for actual product
            // Currently it's commented due to demo purpose - Above url is for demo purpose
            // return "/apps/user/user-view/" + this.params.data.id
        }
    }
}
</script>

<style lang="scss" scoped>
.table-img {
    height: 40px;
    width: 70px;
    margin-right: 10px;
    border-radius: 5px;
}

.h-10 {
    height: 60px;
}

.state-round {
    font-weight: bold;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    margin-top: -5px;
    font-size: 12px;
    color: brown;
    margin-left: 5px;
    // margin-left: -20px;

    .round {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        line-height: 5px;
        background-color: #DAF107;
        display: inline-block;
        margin-right: 5px;
    }
}
</style>
