<template>
  <div>
    <div class="row">
      <div class="col-xl-10 pb-0">
        <b-row>
          <b-col class="col-auto ">
            <img class="logo" :src="client_detail.logo" />
          </b-col>

          <b-col class="col-10 pl-2 d-flex">
            <div>
              <h1 class="main-header">
                <b>{{ client_detail.name }}</b>
              </h1>
              <br />
              <span class="badge badge-pill badge-primary">Performance</span>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="col-xl-2 pb-0">
        <div>
          <v-menu
            ref="dialog"
            v-model="menu"
            offset-y
            :close-on-content-click="true"
            :value="calenderDate"
            width="fit-content"
            color="#F64E70"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="dropdown-heres" v-bind="attrs" v-on="on">
                {{ calenderDate | moment("MMMM") }}
                <span>
                  <v-icon color="#F64E70"> mdi-menu-down </v-icon>
                </span>
              </div>
            </template>
            <v-date-picker
              color="#F64E70"
              v-model="calenderDate"
              type="month"
              no-title
              scrollable
              @change="getAllCampaigns()"
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="row plan-boxes">
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <h1>{{ widgetsData.no_of_campaigns }}</h1>
            <span>No. of Campaigns</span>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <span>AED</span>
            <h1>{{ widgetsData.total_budget }}</h1>
            <span>Total budget</span>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <span>AED</span>
            <h1>{{ widgetsData.actual_cost }}</h1>
            <span>Actual Cost</span>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="plan-box">
          <div class="content-box">
            <span>&nbsp;</span>
            <h1>{{ widgetsData.total_ads }}</h1>
            <span>No. of Ads</span>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="auto" v-if="!show_calendar">
        <button
          class="section-btn"
          :class="currentSection === 'campaign' ? 'active' : ''"
          @click="setCurrentSelection('campaign')"
        >
          Campaign
          <span v-if="selectedCampaign" class="selected-info"
            >{{
              this.selectedCampaign ? this.selectedCampaign.length : 0
            }}
            Selected X</span
          >
        </button>
      </b-col>
      <b-col cols="auto" v-if="!show_calendar">
        <button
          class="section-btn"
          :class="currentSection === 'allocation' ? 'active' : ''"
          @click="setCurrentSelection('allocation')"
          :disabled="!this.selectedCampaign.length > 0"
        >
          Allocation
          <span v-if="selectedCampaign" class="selected-info"
            >for
            {{ this.selectedCampaign ? this.selectedCampaign.length : 0 }}
            campaign</span
          >
        </button>
      </b-col>
      <b-col cols="auto" v-if="!show_calendar">
        <button
          class="section-btn"
          :class="currentSection === 'ads' ? 'active' : ''"
          @click="setCurrentSelection('ads')"
          :disabled="!this.selectedCampaign.length > 0"
        >
          Ads
          <span v-if="selectedAd" class="selected-info"
            >for
            {{ this.selectedAd ? this.selectedAd.length : 0 }}
            Ads</span
          >
        </button>
      </b-col>

      <!-- <v-btn
          @click="openModal"
          class="mx-1 ml-4 mr-4 mdi-plus-bg"
          fab
          dark
          style="height: 42px; width: 42px"
          color="gainsboro"
        >
          <v-icon class="mdi-plus-content">mdi-plus</v-icon>
        </v-btn> -->
      <b-col cols="auto" align-self="center" v-if="!show_calendar">
        <PerfCampaignDropdown
          v-on:createCampaign="openModal('campaign')"
          v-on:createAllocation="openModal('allocation')"
        ></PerfCampaignDropdown>
      </b-col>
      <b-col class="text-right" align-self="end">
        <label for="check-button"
          ><h6 style="padding-top: 3px">
            <b>Switch to Calendar view</b>
          </h6></label
        >
        <b-form-checkbox
          name="check-button"
          size="lg"
          switch
          v-model="show_calendar"
          class="switch-to-calendar-button"
        >
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="row mb-10">
      <div class="col-xl-12">
        <div class="infTable">
          <div style="width: 100%" v-if="!show_calendar">
            <div v-if="currentSection == 'campaign'">
              <campaign
                @campaignUpdated="campaignUpdated"
                :ClientName="client_detail.name"
                :currentSection="currentSection"
              />
            </div>
            <div v-else-if="currentSection == 'allocation'">
              <campaign
                @campaignUpdated="campaignUpdated"
                :ClientName="client_detail.name"
                :currentSection="currentSection"
              />
            </div>
            <div v-else-if="currentSection == 'ads'">
              <campaign
                @campaignUpdated="campaignUpdated"
                :ClientName="client_detail.name"
                :currentSection="currentSection"
              />
            </div>
            <div v-else>
              <h1><b>No campaigns created yet</b></h1>
              <h3>
                <b
                  >click the
                  <v-btn
                    class="mx-1 ml-4 mr-4 mdi-plus-bg2"
                    fab
                    dark
                    color="blue"
                    v-b-modal.create-campaign-modal
                  >
                    <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
                  </v-btn>
                  to add a campaign</b
                >
              </h3>
            </div>
          </div>
          <div style="width: 100%" v-else>
            <demo />
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <!-- create campaign modal -->
    <b-modal
      id="create-campaign-modal"
      title="Overflowing Content"
      hide-header
      hide-footer
    >
      <create-campaign type="add" :ClientName="client_detail.name" />
    </b-modal>

    <!-- create allocation modal -->
    <b-modal
      id="create-allocation-modal"
      title="Overflowing Content"
      hide-header
      hide-footer
    >
      <create-allocation :ClientName="client_detail.name" />
    </b-modal>
  </div>
</template>

<script>
// import ApiService from "../../core/services/api.service";
import moment from "moment";
import { mapGetters } from "vuex";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CellRendererAvatars from "@/view/pages/home/Planners/CellRendererAvatar.vue";
import CellRendererName from "@/view/pages/home/Planners/CellRendererName.vue";
import CellRendererMATERIALS from "@/view/pages/home/Planners/CellRendererMATERIALS.vue";
import CellRendererCoupon from "@/view/pages/home/Planners/CellRendererCoupon.vue";
import CellRendererDropdown from "@/view/pages/CellRenderDropdown.vue";
import CellRendererAction from "@/view/pages/CellRenderAction.vue";
import startplan from "@/view/pages/home/Planners/startplan.vue";
import { AgGridVue } from "ag-grid-vue";
import CreateCampaign from "../../pages/performance_management/campaigns/Create_Campaign";
import CreateAllocation from "../../pages/performance_management/allocations/create-allocation";
// import select_all from "@/view/components/select-all";
// import select_row from "@/view/components/select-row";
import demo from "@/view/components/demo";
import campaign from "@/view/components/campaign";
import allocation from "@/view/components/allocation";
import ads from "@/view/components/ads";
import ApiService from "../../../core/services/api.service";
import PerfCampaignDropdown from "@/view/content/dropdown/PerfCampaignDropdown.vue";
export default {
  name: "performance",
  components: {
    Dropdown7,
    AgGridVue,
    CellRendererAvatars,
    CellRendererName,
    CellRendererMATERIALS,
    CellRendererCoupon,
    startplan,
    CreateCampaign,
    CreateAllocation,
    CellRendererDropdown,
    CellRendererAction,
    // select_all,
    // select_row,
    demo,
    campaign,
    allocation,
    PerfCampaignDropdown,
    ads,
  },
  data() {
    return {
      widgetsData: {
        no_of_campaigns: 0,
        total_budget: 0,
        actual_cost: 0,
        total_ads: 0,
      },
      date: new Date().toISOString().substring(0, 7),
      calenderDate: new Date().toISOString().substr(0, 7),
      Clients: [],
      menu: false,
      client_id: this.$route.params.id,
      searchQuery: "",
      maxPageNumbers: 7,
      gridApi: null,
      StartingPlan: false,
      show_calendar: false,
      currentSection: "campaign",
      totalBudget: 0,
      roi: 0,
      filterClient: [],
      // selectedCampaign: [],
    };
  },
  computed: {
    ...mapGetters(["CampaignGetters", "ClientGetters", "PerfCampaignGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
    selectedCampaign: {
      get() {
        return this.$store.getters.CampaignGetters;
      },
      set(newName) {
        return newName;
      },
    },
    selectedAd: {
      get() {
        return this.$store.getters.PerfCampaignGetters.Perf_ads_array;
      },
      set(newName) {
        return newName;
      },
    },

    // totalBudget: function(){
    //   var sum = 0
    //   this.Clients.campaigns.forEach((element) =>{
    //       this.sum += element.total_budget_remaining
    //       console.log("sum", this.sum);
    //     })
    //     return sum
    //     // console.log("sumReturn", sum);
    // }
  },
  watch: {
    filterdClients() {
      this.xyz();
    },
  },
  beforeMount() {
    this.setCurrentSelection("campaign");
  },
  mounted() {
    this.fetchInfData();
    this.getAllCampaigns();
    this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
  },
  methods: {
    campaignUpdated(campaigns) {
      this.widgetsData.no_of_campaigns = campaigns.length;
      this.widgetsData.total_budget = 0;
      this.widgetsData.actual_cost = 0;
      this.widgetsData.total_ads = 0;
      for (let i = 0; i < campaigns.length; i++) {
        console.log(campaigns[i]);
        this.widgetsData.actual_cost += campaigns[i].actual_cost;
        this.widgetsData.total_budget += parseInt(campaigns[i].budget);
        this.widgetsData.total_ads += parseInt(campaigns[i].total_ads);
      }
    },
    getAllCampaigns() {
      // Get All campaign Array
      this.$store.dispatch("ALL_CAMPAIGN_DATA", {
        year: this.calenderDate.split("-")[0],
        month: this.calenderDate.split("-")[1],
        client_id: this.client_id,
      });
    },
    fetchInfData() {
      ApiService.get(`clients/${this.$route.params.id}`).then((response) => {
        this.Clients = response.data.data;
        this.filterClient = response.data.data;
        this.Clients.campaigns.forEach((element) => {
          this.totalBudget += element.total_budget_remaining;
          this.roi += element.roi / this.Clients.length;
          // console.log(element.created_at.split('-'))
        });
      });
    },
    xyz() {
      // console.log('date', this.calenderDate.split('-'))
      let month = this.calenderDate.split("-")[1];
      this.Clients = this.filterClient.campaigns.filter(
        (x) => x.created_at.split("-")[1] == month
      );
      console.log(this.Clients);
    },

    // OnCalenderDateChange() {
    //   this.$refs.dialog.save(this.calenderDate);
    //   console.log(moment(this.calenderDate).format("M"));
    //   console.log(moment(this.calenderDate).format("Y"));
    // },

    onGridReady(params) {
      // console.log(params);
      // this.gridApi = params.api;
      // this.columnApi = params.columnApi;
    },
    setCurrentSelection(val) {
      this.currentSection = val;
      this.selectedCampaign = [];
      this.selectedAd = [];
      // this.$store.commit(INF_ADS_MUTATION, []);
      console.log(this.selectedAd);
    },
    openModal(selection) {
      switch (selection) {
        case "campaign":
          this.$bvModal.show("create-campaign-modal");
          break;
        case "allocation":
          this.$bvModal.show("create-allocation-modal");
          break;
        default:
          break;
      }
    },
    onSelectCampaign(campaign) {
      if (!campaign.node.selected) {
        this.selectedCampaign = null;
        return;
      }
      this.selectedCampaign = this.campaigns.find(
        (x) => x.id === campaign.rowIndex
      );
    },
  },
};
</script>

<style lang="scss">
// @import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.min.css";
// @import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css";
.logo {
  height: 85px;
  width: 80px;
  border-radius: 20px;
  border: 2px solid white;
  box-shadow: gainsboro 0px 0px 5px;
}
.inf-table {
  box-shadow: 0px 0px 8px 2px #f5f5f5;
  border-radius: 20px;
  padding: 1rem;
  padding-top: 4rem;
  min-height: 500px;
  align-items: center;
  font-family: "Poppins";
}

.plan-box {
  background-color: #f8fafc;
  min-height: 170px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: "Poppins";

  span:first-of-type {
    display: block;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    font-family: "Poppins";
  }

  h1 {
    font-weight: bold;
    color: #000;
    margin: 10px 0;
    font-family: "Poppins";
  }

  span:last-of-type {
    display: block;
    text-transform: capitalize;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    font-family: "Poppins";
  }
}

.dropdown-heres {
  color: #f64e70 !important;
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;
  margin-left: 40px;
  font-family: "Poppins";
}

.planing {
  text-align: center;
  margin: 0 auto;
  box-shadow: 0px 0px 8px 2px #f5f5f5;
  border-radius: 20px;
  padding: 2rem;
  font-family: "Poppins";

  h1 {
    font-weight: bold;
    font-family: "Poppins";
  }

  .sub-title {
    color: #5ebb46;
    font-weight: bold;
    font-family: "Poppins";
  }

  .plan-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
  }
}

.mdi-plus-bg {
  background-color: rgb(241, 241, 241) !important;
  box-shadow: none !important;
}
.mdi-plus-bg2 {
  background-color: rgb(63, 99, 218) !important;
  width: 25px !important;
  height: 25px !important;
  box-shadow: none !important;
}
.mdi-plus-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: black !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  line-height: normal;
  position: relative;
}
.mdi-plus-content2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  line-height: normal;
  position: relative;
}
.switch-btn-div {
  display: flex;
  justify-content: space-between;
}
.switch-to-calendar-button {
  // display: flex;
  margin-left: 10px;
  display: inline;
  align-items: right;
}
.mdi-round {
  display: flex;
  justify-content: space-between;
}
.switch-button-div {
  display: flex;
  font-family: "Poppins";
  justify-content: space-between;
}
.campaigns-header {
  padding-top: 15px;
  display: inline;
  font-family: "Poppins";
}
.section-btn {
  width: 190px;
  height: 42px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  font-family: "Poppins";
}
.active {
  background: #1374d6 0% 0% no-repeat padding-box;
  color: #fff;
}
.selected-info {
  display: block;
  font-size: 11px;
}

.ag-theme-material {
  .ag-header-cell-text {
    font-size: 12px;
    font-weight: bold;
    color: #000000;
  }
  .ag-checkbox-input::after {
    background: #82ceca !important;
  }
}

.ag-theme-material .ag-paging-button {
  background-color: #f3fcff;
  padding: 5px;
  border-radius: 5px;
  color: #82ceca;
  font-weight: bold;
}
@media (min-width: 576px) {
  .modal-dialog,
  .modal-md {
    max-width: 40% !important;
  }
}
.info {
  background-color: lightblue !important;
}
// #view_plan___BV_modal_content_ {

//   margin-left: -34%;
// }
.modal-date {
  color: red;
  font-size: 0.9rem;
}
.modal-comment-box {
  background: #f1f1f1;
  min-width: 20%;
  max-width: fit-content;
  height: 40%;
  margin: 1%;
  padding: 1%;
  border-radius: 10%;
}
// .modal-comment-box p{
//   color: red;
// }
.modal-comment-box h3 {
  font-weight: 900;
}
.infTable {
  padding-top: 0px !important;
}
// .content-box{
//       background-color: #f8fafc !important;

// }
</style>
