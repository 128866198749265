<template>
<div style="text-align: center;">
    <div class="row">
        <div class="col-xl-12">
            <img v-if="params.value.length > 0" :src="getImg(params.value)" class="bag-img " alt="" /><br>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'CellRendererLink',
    methods:{
        getImg(value){
            console.log(value)
            if(value == '0'){
                return 'media/misc/shopping-bags.png';
            }
            else{
                return 'media/misc/shopping-bags2.png';
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.bag-img {
    height: 30px;
    width: 30px;
    border-radius: 5px;
}

.h-10 {
    height: 60px;
}

.state-round {
    font-weight: bold;
    color: #000;
    padding:5px;
    border-radius: 5px;
    // margin-left: -20px;

    .round {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        line-height: 5px;
        background-color: #DAF107;
        display: inline-block;
        margin-right: 5px;
    }
}
</style>
