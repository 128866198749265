<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mr-4"
    no-caret
    right
    no-flip
    style="float: right"
  >
    <template v-slot:button-content>
      <v-btn class="mx-2" fab dark style="background: #1374d6">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text tag="div" class="navi-item" @click="createCampaign">
        <a class="navi-link">
          <span class="navi-text">Create New Campaign</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item" @click="createAllocation">
        <a class="navi-link">
          <span class="navi-text">Create New Allocation</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss" scoped>
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
.v-btn--fab.v-size--default {
  height: 45px;
  width: 45px;
}
::v-deep .navi-text {
  font-family: "Poppins";
  color: black;
  font-weight: normal;
}
::v-deep .v-application ul,
.v-application ol {
  padding-left: 0px !important;
}
</style>

<script>
export default {
  name: "PerfCampaignDropdown",
  components: {},
  mounted() {},
  methods: {
    createCampaign() {
      this.$emit("createCampaign", "");
    },
    createAllocation() {
      this.$emit("createAllocation", "");
    },
  },
};
</script>
