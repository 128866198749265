<template>
  <b-modal
    id="edit-campaign-modal"
    title="Overflowing Content"
    hide-header
    hide-footer
  >
    <div class="edit-campaign">
      <b-row>
        <b-col>
          <span class="main-heading">Edit Campaign</span>
          <br />
          <span class="sub-heading">for {{ row.title }}</span>
        </b-col>
        <b-col
          cols="auto"
          role="button"
          @click="$bvModal.hide('edit-campaign-modal')"
          class="close"
        >
          close
        </b-col>
      </b-row>

      <form ref="form" class="form-data">
        <!-- ADD DATE -->
        <b-form-group id="fieldset" label="Add Date" label-for="add-date">
          <b-row id="add-date">
            <b-col class="pb-0 pt-0">
              <b-form-group
                id="fieldset-start-date"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                label="from"
                label-for="start-date"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="startDate"
                  @context="onSelectStartDate"
                  :disabled="isFullMonthCampaign"
                  placeholder="1/10/2020"
                  locale="en"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col class="pb-0 pt-0">
              <b-form-group
                id="fieldset-end-date"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                label="to"
                label-for="end-date"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="endDate"
                  :disabled="isFullMonthCampaign"
                  @context="onSelectEndDate"
                  placeholder="31/10/2020"
                  locale="en"
                  :min="min"
                  :max="max"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="startDate">
            <b-col class="pt-0">
              <b-form-checkbox
                class="custom-control custom-checkbox checkbox-xl"
                v-model="isFullMonthCampaign"
                @change="toggleFullMonth"
              >
                <span class="labels campaign-label">
                  FULL MONTH CAMPAIGN
                  <span style="color: #f76d67">(31 DAYS)</span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- ADD NAME -->

        <b-form-group
          id="name-fieldset"
          class="input-label mt-15"
          label="Campaign Name"
          label-for="compaignName"
        >
          <b-form-input
            id="compaignName"
            v-model="form.title"
            value="usadmma"
            class="input-field custom-input"
            type="text"
            required
            disabled
            placeholder="Adidas, October 2020 Campaign"
          />
        </b-form-group>

        <!-- ADD BUDGET -->

        <b-form-group
          id="budget-fieldset"
          class="input-label mt-15 col-6 pl-0"
          label="Add Budget"
          label-for="budget"
        >
          <b-input-group type="number" class="mt-3">
            <template #prepend>
              <b-dropdown :text="budget_currency" class="currency-dropdown">
                <b-dropdown-item @click="budget_currency = 'AED'"
                  >AED</b-dropdown-item
                >
                <b-dropdown-item @click="budget_currency = 'USD'"
                  >USD</b-dropdown-item
                >
                <b-dropdown-item @click="budget_currency = 'PKR'"
                  >PKR</b-dropdown-item
                >
              </b-dropdown>
            </template>
            <b-form-input
              id="budget"
              v-model="form.budget"
              class="input-field custom-input"
              type="text"
              required
              placeholder="AED 400"
            />
          </b-input-group>
        </b-form-group>

        <!-- ADD TARGET -->
        <b-form-group class="input-label mt-15 col-12 pl-0">
          <label
            >Add Target
            <v-btn
              @click="toggleTargetOption"
              class="mx-1 ml-4 mr-4 mdi-plus-bg2"
              fab
              dark
              color="blue"
            >
              <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
            </v-btn>
          </label>
          <b-row v-if="showTarget">
            <!-- Target -->
            <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">TARGET </label>
                <b-input-group type="number">
                  <template #prepend>
                    <b-dropdown
                      :text="target.target_currency"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item @click="target.target_currency = 'AED'"
                        >AED</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.target_currency = 'USD'"
                        >USD</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.target_currency = 'PKR'"
                        >PKR</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>

                  <b-form-input
                    type="number"
                    v-model="target.target"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">REVENUE </label>
                <b-input-group type="number">
                  <template #prepend>
                    <b-dropdown
                      :text="target.revenue_currency"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item @click="target.revenue_currency = 'AED'"
                        >AED</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.revenue_currency = 'USD'"
                        >USD</b-dropdown-item
                      >
                      <b-dropdown-item @click="target.revenue_currency = 'PKR'"
                        >PKR</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>
                  <b-form-input
                    type="number"
                    id="revenue"
                    v-model="form.cost"
                    class="input-field custom-input"
                    placeholder="AED 250,000"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">ROI </label>
                <b-form-input
                  id="roi"
                  v-model="target.roi"
                  class="input-field custom-input"
                  type="text"
                  placeholder="3.1"
                />
              </b-form-group>
            </b-col>

            <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">ORDERS </label>
                <b-form-input
                  id="orders"
                  v-model="target.orders"
                  class="input-field custom-input"
                  type="number"
                  placeholder="120,000"
                />
              </b-form-group>
            </b-col>

            <!-- COMMISSION -->
            <b-col class="pb-0 col-6 col-md-4">
              <b-form-group class="input-label">
                <label class="sub-label">COMMISSION </label>
                <b-input-group type="number">
                  <template #prepend>
                    <b-dropdown
                      :text="target.commission_currency"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item
                        @click="target.commission_currency = 'AED'"
                        >AED</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="target.commission_currency = 'USD'"
                        >USD</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="target.commission_currency = 'PKR'"
                        >PKR</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>

                  <b-form-input
                    type="number"
                    v-model="target.commission"
                    placeholder="60,000"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- ADD SPECIAL PAYOUT -->
        <b-form-group class="input-label mt-15 col-12 pl-0">
          <label
            ><b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              @change="showPayout = !showPayout"
            >
              Special Payout
            </b-form-checkbox>
          </label>
          <b-input-group v-if="showPayout" class="mt-3">
            <template #prepend>
              <b-dropdown :text="payout.currencyType" class="currency-dropdown">
                <b-dropdown-item @click="payout.currencyType = 'AED'"
                  >AED</b-dropdown-item
                >
                <b-dropdown-item @click="payout.currencyType = 'USD'"
                  >USD</b-dropdown-item
                >
                <b-dropdown-item @click="payout.currencyType = 'PKR'"
                  >PKR</b-dropdown-item
                >
              </b-dropdown>
            </template>

            <b-form-input type="number" v-model="payout.amount"></b-form-input>

            <template #append>
              <b-dropdown :text="payout.payoutType" class="payout-dropdown">
                <b-dropdown-item @click="payout.payoutType = 'CPO'"
                  >CPO</b-dropdown-item
                >
                <b-dropdown-item @click="payout.payoutType = 'CPS'"
                  >CPS</b-dropdown-item
                >
                <b-dropdown-item @click="payout.payoutType = 'CPC'"
                  >CPC</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-input-group>
        </b-form-group>

        <!-- ADD OFFERS -->
        <b-form-group class="input-label mt-15 col-12 pl-0">
          <label
            >Add Offers
            <v-btn class="mx-1 ml-4 mr-4 mdi-plus-bg2" fab dark color="blue">
              <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
            </v-btn>
          </label>
          <b-row>
            <b-col class="pb-0 col-6 col-md-4">
              <div class="offer-sec">Black Friday 15% off</div>
            </b-col>

            <b-col class="pb-0 col-6 col-md-4">
              <div class="offer-sec">Back to School 50% off</div>
            </b-col>

            <b-col class="pb-0 col-6 col-md-4">
              <div class="offer-sec">Cyber Monday 15% off</div>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- ADD COUPONS -->
        <b-form-group
          id="name-fieldset"
          class="input-label mt-15"
          label="Add Coupons"
          label-for="coupons"
        >
          <b-form-input
            id="coupons"
            v-model="coupons"
            class="input-field custom-input"
            type="text"
            required
            placeholder="AE471, AE435"
          />
        </b-form-group>

        <!-- ADD FOCUS ON PRODUCTS -->
        <b-form-group class="input-label mt-15 col-12 pl-0">
          <label
            ><b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              @change="focusOnProducts = !focusOnProducts"
            >
              Focus on Products
            </b-form-checkbox>
          </label>
          <b-form-input
            v-if="focusOnProducts"
            id="coupons"
            v-model="products"
            class="input-field custom-input mt-3"
            type="text"
            required
            placeholder="Sliders, Sneakers, Boots"
          />
        </b-form-group>

        <!-- ADD FOCUS ON GEO -->
        <b-form-group class="input-label mt-15 col-12 pl-0">
          <label
            ><b-form-checkbox
              class="custom-control custom-checkbox checkbox-xl"
              @change="focusOnGeo = !focusOnGeo"
            >
              Focus on GEO
            </b-form-checkbox>
          </label>
          <b-form-input
            v-if="focusOnGeo"
            id="coupons"
            v-model="geos"
            class="input-field custom-input mt-3"
            type="text"
            required
            placeholder="Dubai, Dammam"
          />
        </b-form-group>
        <!-- ADD BUTTON -->
        <b-form-group class="mt-15">
          <b-button class="add-btn" @click="handleSubmit"
            >Save <b-spinner v-if="isLoading" small type="grow"></b-spinner>
          </b-button>
        </b-form-group>
      </form>
    </div>
  </b-modal>
</template>

<script>
import ApiService from "../../../../core/services/api.service";
import { mapGetters } from "vuex";
export default {
  name: "edit-campaign",
  props: ["row", "ClientName"],
  components: {},
  data: () => ({
    //   form:null,
    startDate: null,
    startCompleteDate: null,
    endCompleteDate: null,
    endDate: null,
    isFullMonthCampaign: false,
    items: [
      "Black Friday 15% off",
      "Back to School 50% off",
      "Cyber Monday 15% off",
    ],
    name: null,
    budget: "",
    target: {
      target: null,
      revenue: null,
      roi: null,
      orders: null,
      commission: null,
      target_currency: "AED",
      revenue_currency: "AED",
      commission_currency: "AED",
    },
    payout: {
      payoutType: "CPO",
      amount: null,
      currencyType: "AED",
    },
    coupons: null,
    products: [],
    geos: [],
    couponsArray: [],
    min: null,
    max: null,
    firstDay: null,
    lastDay: null,
    showTarget: false,
    showPayout: false,
    focusOnProducts: false,
    focusOnGeo: false,
    isLoading: false,
  }),
  mounted() {
    //   console.log("Edit Campaign", this.row)
    this.form = this.row;
    this.target.commission = this.form.commission;
    this.target.orders = this.form.profit;
  },
  methods: {
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.firstDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth(),
        1
      );
      this.lastDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth() + 1,
        0
      );

      // set date min and max range
      this.min = new Date(this.firstDay);
      this.min.setDate(ctx.activeDate.getDate());
      this.max = new Date(this.lastDay);

      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      if (!this.endDate || this.isFullMonthCampaign) {
        return;
      }
      this.endCompleteDate = ctx.activeDate;
      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },

    /* toggle full month campaign  */
    toggleFullMonth(val) {
      // set month start and end date if user select full month campaign check.
      if (val) {
        this.startDate = this.toJSONLocal(this.firstDay);
        this.endDate = this.toJSONLocal(this.lastDay);
      }
      // auto fill campaign name
      this.name = this.getAutoCampaignName();
    },
    toJSONLocal(date) {
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },
    /* get formatted auto campaign name according to dates. */
    getAutoCampaignName() {
      let month = this.startCompleteDate.toLocaleString("default", {
        month: "long",
      });
      let dateRange =
        this.isFullMonthCampaign || !this.endCompleteDate
          ? ""
          : `${this.startCompleteDate.getDate()} - ${this.endCompleteDate.getDate()}`;
      let year = this.startCompleteDate.getFullYear();
      return;
      return `Adidas, ${dateRange} ${month} ${year} Campaign`;
    },

    toggleTargetOption() {
      this.showTarget = !this.showTarget;
    },
    handleSubmit() {
      let dataToPost = {
        client_id: this.$route.params.id,
        campaign_id: this.selectedCampaign[0]._id,
        title: this.form.title,
        description: "this is description from web Update",
        // status: "new",
        budget: this.form.billable_amount,
        budget_currency: "AED",
        target: this.form.cost,
        target_sales_currency: "AED",
        exp_orders: "100",
        platform_type: "PERFORMANCE",
        // start: this.startDate,
        // end: this.endDate,
        // is_inf_camp: true,
      };
      {
      }
      console.log("edit Campaign", dataToPost);
      this.isLoading = true;
      ApiService.post("/campaigns/update", dataToPost).then((response) => {
        console.log("response ", response.data.data.message);
        this.isLoading = false;
        this.$bvModal.hide("edit-campaign-modal");
        this.$emit("refresh", response.data.data.message);
      });
    },
  },
  computed: {
    ...mapGetters(["CampaignGetters"]),
    selectedCampaign() {
      return this.$store.getters.CampaignGetters;
    },
    form: {
      get() {
        return this.row;
      },
      set(newVal) {
        return newVal;
      },
    },
    isFormValidated() {
      // if (
      //   this.showTarget && !( this.target.revenue ||  this.target.orders ||    this.target.roi || this.target.commission ) &&!(this.startDate)
      // ) {
      //   return false;
      // }

      // return true;
      if (
        // this.startDate &&
        // this.endDate &&
        this.form.billable_amount &&
        this.form.cost
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.create-campaign,
.edit-campaign {
  font-family: "Poppins";

  .main-heading {
    font-weight: bold;
    font-size: 24px !important;
    color: #000000;
    font-family: Poppins;
  }
  .sub-heading {
    font-size: 14px !important;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 16px !important;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .profit-text {
    font-size: 22px !important;
    font-weight: 600;
    line-height: 2.25;
  }
  .inf-detail-label {
    margin-left: -12px;
  }
  .form-data {
    margin-top: 10px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 45px !important;
      font-size: 16px !important;
    }
    select,
    input {
      height: 45px !important;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px !important;
      color: #000000;
    }
    textarea {
      min-height: 200px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px !important;
      color: #000000;
    }
    #checkbox-1::after {
      width: 25px !important;
      height: 23px !important;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }
    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 25px !important;
      height: 23px !important;
      border-radius: 8px;
      opacity: 1;
    }
    .checkbox-xl .custom-control-label {
      padding-left: 24px;
      padding-top: 0px;
    }

    .multiselect__content-wrapper {
      ul {
        padding-left: 0;
      }
    }
    .multiselect__tags {
      border: none !important;
      min-height: 45px !important;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font-size: 16px !important;
      color: #000000;
      border: 1px solid #e4e6ef !important;
    }
    .multiselect__tag {
      background-color: #000;
    }
    .multiselect__tag-icon:hover {
      background: var(--v-accent-base);
    }
    .multiselect__select::before {
      top: 90%;
    }
    .v-text-field .v-input__slot {
      border-radius: 8px;
      border: none !important;
    }
    label {
      align-self: center;
      font-size: 14px !important;
      color: #000000;
    }
    .sub-label {
      font-size: 12px !important;
      font-weight: 600 !important;
    }
    .custom-control-input {
      display: none;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 14px/22px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 150px;
      height: 45px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .payout-dropdown {
      button,
      button:focus {
        background: black;
        color: #fff;
        font-size: 16px !important;
        font: normal normal bold 12px/25px Poppins;
      }
      button::after {
        font-size: 16px;
        color: #fff;
      }
    }
    .currency-dropdown {
      button,
      button:focus {
        background: #f3fcff;
        color: #000000;
        font-size: 16px;
        font-weight: 500;
      }
      button::after {
        font-size: 10px;
        color: #000000;
      }
    }
    .v-input input,
    .v-input textarea:focus,
    .v-input textarea:active {
      background-color: transparent;
      margin-top: 0.5rem;
    }
    .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 45px !important;
      font-size: 14px !important;
    }
    .currency-dropdown .dropdown-menu {
      padding: 0.2rem;
    }
  }
}
</style>
