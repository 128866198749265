<template>
<div >
    <div class="row">
        <div class="col-xl-6">
            <span v-if="params.value.length > 0"  class="copon" style="font-weight:bold">{{params.value}} </span>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'CellRendererLink',
    methods: {
        statee(sta) {
            if (sta == 'Regular') {
                return "#FFF0D9";
            } else if (sta == 'New') {
                return "#FFFCBF";
            } else if (sta == 'Contacted') {
                return "#C8FFBA";
            } else if (sta == 'Standby') {
                return "#E2060B";
            }
        },
    },
    computed: {
        url() {
            return '/myitems/edit/' + this.params.data.id

            // Below line will be for actual product
            // Currently it's commented due to demo purpose - Above url is for demo purpose
            // return "/apps/user/user-view/" + this.params.data.id
        }
    }
}
</script>

<style lang="scss" scoped>
.copon{
    background-color: #D2F3F2;
    color: #1C97C3;
    padding:8px 10px;
    border-radius: 10px;
}
</style>
