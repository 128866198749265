<template>
  <div style="width:100%;">
    <div class="d-flex justify-content-around">
      <!-- <span v-b-modal.view_plan class="fa fa-arrow-alt-circle-right" data-toggle="modal" data-target="#modal-1"></span> -->
      <!-- <span v-b-modal.edit-campaign-modal @click="edit" class="fa fa-arrow-alt-circle-down"></span> -->
      <div
        class="ac_btn share-img"
        @click="view"
        v-if="viewable && params.data.media_plan.length > 0"
      >
        <img :src="active_img" />
      </div>

      <div class="ac_btn" @click="view" v-else>
        <img :src="view_img" />
      </div>
      <div class="ac_btn" @click.prevent="edit" v-if="editable">
        <img :src="edit_img" />
      </div>
      <div
        class="ac_btn"
        :id="modal_id"
        @click="confrimation_modal"
        v-if="deleteable"
      >
        <img :src="delete_img" />
      </div>

      <!-- <span @click="view" class="action_btn fa fa-arrow-alt-circle-right" ></span> -->
      <!-- <span  @click="edit" class="action_btn fa fa-arrow-alt-circle-down"></span> -->
      <!-- <span   :id="modal_id" @click="confrimation_modal" class="action_btn fa fa-arrow-alt-circle-left"></span> -->
    </div>

    <span v-if="params.data.media_plan.length > 0" class="media-plan"
      >view action plan</span
    >
    <b-modal
      :id="'del_row_modal-' + modal_id"
      :title="'Confirmation ?' + modal_id"
      ok-variant="danger"
      ok-title="Remove"
      @ok="del"
    >
      <h3>Are you sure you want to remove this item?</h3>
    </b-modal>
  </div>
</template>

<script>
export default {
  // name: 'CellRenderDropdown',
  name: "CellRendererAction",
  data() {
    return {
      modal_id: null,
      view_img: "media/action/view.svg",
      active_img: "media/action/active.svg",
      edit_img: "media/action/edit.svg",
      delete_img: "media/action/delete.svg",
      viewable: false,
      editable: false,
      deleteable: false,
    };
  },
  mounted() {
    // console.log(this.params.data._id)
    this.modal_id = this.params.data._id;
    this.viewable = this.params.context.viewable;
    this.editable = this.params.context.editable;
    this.deleteable = this.params.context.deleteable;
  },
  methods: {
    confrimation_modal() {
      let ul = "del_row_modal-" + this.modal_id;
      console.log(ul);
      this.$bvModal.show(ul);
    },
    view() {
      this.params.context.parent.viewGridAction(this.params.data);
    },
    edit() {
      // console.log("cell rendered action ", this.params.data.title);
      this.params.context.parent.editGridAction(this.params.data);
    },
    del() {
      this.params.context.parent.deleteGridAction(this.params.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-plan {
  font-size: 9px;
  text-decoration: underline;
}
.d-flex {
  justify-content: space-around;
}
.action_btn {
  font-size: 16px;
}
.share-img {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 28px;
    height: 28px;
  }
}
</style>
