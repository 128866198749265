
<template>
<div style="text-align: center;">
    <div>
        <span v-b-modal.comment class="fa fa-comment-dots"></span>
    </div>

</div>
</template>


<script>
export default {
    // name: 'CellRenderDropdown',
    name: 'CellRendererAction',
    computed: {
    }
}
</script>

<style lang="scss" scoped>
</style>
