<template>
  <v-list>
    <template class="my-0 py-0">
      <!-- <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          ></v-divider> -->

      <v-list-item class="mt-0 pt-0 pl-0 my-auto">
        <v-list-item-avatar rounded size="33" class="my-auto">
          <v-img :src="params.data.influencer_image ? params.data.influencer_image : params.data.image"></v-img>
        </v-list-item-avatar>
        <v-list-item-title class="ml-1 my-1 text_prof_head">
          {{ params.data.influencer_name }} <br />
          <span class="text--primary inside_text">
            <v-list-item-avatar size="20" class="my-auto">
              <v-img :src="selectedIcon"> </v-img>
            </v-list-item-avatar>
            {{ params.data.influencer_username }}</span
          >
        </v-list-item-title>

        <!-- <v-list-item-content class="">
          <v-list-item-subtitle class="px-2 py-0 my-0 "
            ><span class="text--primary">
              {{ item.subtitle }}</span
            ></v-list-item-subtitle
          >
        </v-list-item-content> -->
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  computed: {
    selectedIcon() {
      let selected = this.socialMedia.find(
        (x) => x.name.toLowerCase() == this.params.data.social_media.toLowerCase()
      );
      return selected.image;
    },
  },
  data: () => ({
    items: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        social:
          "https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/podcast-256.png",
        title: "Khalid",
        subtitle: `Khalid123`,
      },
    ],
    socialMedia: [
      {
        name: "Snapchat",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/64/social_media_logo_snapchat-1024.png",
      },
      {
        name: "Facebook",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/online_social_media_facebook-512.png",
      },
      {
        name: "Twitter",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/twitter_online_social_media-512.png",
      },
      {
        name: "Instagram",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/instagram_online_social_media_photo-512.png",
      },
      {
        name: "YouTube",
        image:
          "https://cdn2.iconfinder.com/data/icons/black-white-social-media/32/youtube_online_social_media_tube-512.png",
      },
      {
        name: "TikTok",
        image:
          "https://cdn2.iconfinder.com/data/icons/social-media-2421/512/TikTok-512.png",
      },
    ],
  }),
};
</script>

<style scoped>
.Profile__mgt {
  margin: 0px;
  padding: 0px;
}
p {
  height: 1rem;
}
.text_prof {
  height: 3rem;
  margin-left: -0.5rem !important;
  display: block;
  margin-top: -5.5rem;
  font-weight: 400;
  font-size: 0.8rem !important;
  margin-left: 0rem;
}
.text_prof_head {
  /* letter-spacing: 0.12ch; */
  font-weight: bolder;
  /* height: 3rem; */
  /* font-size: 1.1rem; */
}
span {
  font-size: 1rem;
}
img {
  height: 1rem;
}
.inside_text {
  margin-left: 0.1rem;
  font-size: 0.8rem;
  font-weight: 300;
  color: gray;
}
</style>
