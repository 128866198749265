<template>
  <div>
    <b-dropdown
      ref="targetDropdown"
      size="sm"
      variant="link"
      toggle-class="btn-sm btn-icon mr-4"
      no-caret
      no-flip
    >
      <template v-slot:button-content>
        <v-btn class="mx-1 ml-4 mr-4 mdi-plus-bg2" fab dark color="blue">
          <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
        </v-btn>
      </template>

      <!--begin::Navigation-->
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text
          tag="div"
          class="navi-item"
          @click="emitTargetType('revenue')"
        >
          <a href="#" class="navi-link">
            <span class="navi-text">Revenue</span>
          </a>
        </b-dropdown-text>
        <b-dropdown-text
          tag="div"
          class="navi-item"
          @click="emitTargetType('roi')"
        >
          <a href="#" class="navi-link">
            <span class="navi-text">ROI</span>
          </a>
        </b-dropdown-text>
        <b-dropdown-text
          tag="div"
          class="navi-item"
          @click="emitTargetType('orders')"
        >
          <a href="#" class="navi-link">
            <span class="navi-text">Orders</span>
          </a>
        </b-dropdown-text>
        <b-dropdown-text
          tag="div"
          class="navi-item"
          @click="emitTargetType('commission')"
        >
          <a href="#" class="navi-link">
            <span class="navi-text">Commission</span>
          </a>
        </b-dropdown-text>
      </div>
      <!--end::Navigation-->
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
.v-btn--fab.v-size--default {
  height: 24px;
  font-size: 24px;
  width: 24px;
}
::v-deep .navi-text {
  font-family: "Poppins";
  color: black;
  font-weight: normal;
}
::v-deep .v-application ul,
.v-application ol {
  padding-left: 0px !important;
}
::v-deep .dropdown-toggle {
  text-decoration: none;
}
</style>

<script>
export default {
  name: "PerfCampTargetDropdown",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    emitTargetType(val) {
      this.$emit("targetType", val);
      this.$refs.targetDropdown.hide(true);
    },
  },
};
</script>
