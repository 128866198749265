<template>
  <div class="create-allocation">
    <b-row class="mb-10">
      <b-col>
        <span class="main-heading"
          >{{ type == "edit" ? "Edit" : "Create" }} Allocation</span
        >
        <br />
        <span class="sub-heading">for {{ ClientName }}</span>
      </b-col>
      <b-col
        cols="auto"
        role="button"
        @click="
          $bvModal.hide(
            `${type == 'edit' ? 'edit' : 'create'}-allocation-modal`
          )
        "
        class="close"
      >
        close
      </b-col>
    </b-row>

    <form ref="form" class="form-data">
      <!-- Select campaign -->
      <!-- <b-form-group
        id="campaign-fieldset"
        class="input-label"
        label="Select Campaign"
        label-for="selectCampaign"
      >
        <b-dropdown v-model="selectedCampaign">
          <b-dropdown-item>Expo campaing</b-dropdown-item>
          <b-dropdown-item>Expo campaing 2</b-dropdown-item>
        </b-dropdown>
      </b-form-group> -->
      <v-row>
        <v-col cols="12">
          <label>Select Campaign</label>
          <v-autocomplete
            class="pt-0 mt-0"
            attach="attach"
            rounded
            placeholder="Type To Search Campaign"
            v-model="selectedCamp"
            :items="campaigns"
            item-text="title"
            item-value="_id"
            return-object
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <!-- Allocation name -->
      <b-form-group
        id="allocation-fieldset"
        class="input-label"
        label="Add Allocation Name"
        label-for="allocation"
      >
        <b-input placeholder="Add allocation name" v-model="title" />
      </b-form-group>

      <!-- ADD DATE -->
      <b-form-group id="fieldset" label="Add Date" label-for="add-date">
        <b-row id="add-date">
          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-start-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="from"
              label-for="start-date"
            >
              <b-form-datepicker
                id="start-date"
                :disabled="!selectedCamp"
                v-model="startDate"
                @context="onSelectStartDate"
                placeholder="1/10/2020"
                locale="en"
                :min="selectedCamp.start_date"
                :max="selectedCamp.end_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-end-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="to"
              label-for="end-date"
            >
              <b-form-datepicker
                id="end-date"
                :disabled="!selectedCamp"
                v-model="endDate"
                @context="onSelectEndDate"
                @change="calculateDateDiff"
                placeholder="31/10/2020"
                locale="en"
                :min="selectedCamp.start_date"
                :max="selectedCamp.end_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-0">
          <b-col class="pt-0">
            <span
              v-if="startDate && endDate"
              class="days-info"
              @click="calculateDateDiff"
              >{{ dateDifference }} Days</span
            >
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD BUDGET | ADD TARGET | ORDER | -->
      <b-row>
        <b-col class="col-12 col-md-6">
          <b-form-group
            id="target-fieldset"
            class="input-label"
            label="Add Budget"
            label-for="addBudget"
          >
            <b-input-group class="mt-3">
              <template #prepend>
                <b-dropdown :text="budget_currency" class="currency-dropdown">
                </b-dropdown>
              </template>
              <template #append>
                <div class="range-wrapper">
                  <b-button
                    class="pl-2 pr-2"
                    @click="decrease"
                    :disabled="budgetPercentage <= 0"
                    >-</b-button
                  >
                  <span
                    >{{
                      budgetPercentage && budgetPercentage.toFixed(1)
                    }}%</span
                  >
                  <b-button
                    class="pl-2 pr-2"
                    @click="increase"
                    :disabled="budgetPercentage >= 100"
                    >+</b-button
                  >
                </div>
              </template>
              <b-form-input
                id="addBudget"
                class="input-field custom-input"
                type="number"
                required
                :disabled="!selectedCamp"
                v-model="val"
                :max="constBudget"
                :min="0"
                :formatter="formatBudgetInput"
                @keyup="changeBudgetValue"
                placeholder="100"
              />
            </b-input-group>
            <span class="input-info">
              {{ budget_currency }}
              <!-- {{
                remaining_budget - (budgetPercentage / 100) * remaining_budget
              }} -->
              {{ varBudget.toFixed(2) }}
              <!-- {{ remaining_budget }} -->
              remaining
            </span>
          </b-form-group>
          <!-- {{ (budgetPercentage / 100) * totalBudget }} -->
          <!-- REMOVE THE VALUE DISPALING HERE -->
        </b-col>

        <b-col class="col-12 col-md-6">
          <b-form-group
            style="text-transform: capitalize;"
            id="target-fieldset"
            class="input-label"
            :label="'Add Target ' + targetType"
            label-for="Commission"
          >
            <b-input-group class="mt-3">
              <template
                #prepend
                v-if="
                  targetType &&
                    (targetType == 'Revenue' || targetType == 'Commission')
                "
              >
                <b-dropdown :text="budget_currency" class="currency-dropdown">
                </b-dropdown>
              </template>
              <template #append>
                <div class="range-wrapper">
                  <b-button
                    class="pl-2 pr-2"
                    @click="decreaseTarget"
                    :disabled="
                      targetPercentage <= 0 ||
                        targetValue === 0 ||
                        !selectedCamp
                    "
                    >-</b-button
                  >
                  <span
                    >{{
                      targetPercentage && targetPercentage.toFixed(1)
                    }}%</span
                  >
                  <b-button
                    class="pl-2 pr-2"
                    @click="increaseTarget"
                    :disabled="
                      targetPercentage >= 100 ||
                        targetValue === 0 ||
                        !selectedCamp
                    "
                    >+</b-button
                  >
                </div>
              </template>
              <b-form-input
                id="target"
                v-model="targetVal"
                @keyup="changeTargetValue"
                class="input-field custom-input"
                :disabled="targetValue === 0 || !selectedCamp"
                type="number"
                :formatter="formatTargetInput"
                :max="constTarget"
                :min="0"
                required
                placeholder="100"
              />
            </b-input-group>
            <span class="input-info">
              {{
                targetType &&
                (targetType == "Revenue" || targetType == "Commission")
                  ? target_currency
                  : ""
              }}
              <!-- {{ totalTarget - (targetPercentage / 100) * totalTarget }} -->
              <!-- {{ remaining_target }} -->
              {{ varTarget.toFixed(2) }}
              remaining
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- ADD BUTTON -->
      <b-form-group class="mt-15">
        <b-button
          :disabled="!isFormValidated || isLoading"
          class="add-btn"
          @click="handleSubmit"
          >{{ type == "edit" ? "Update" : "Add" }}
          <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
.currency-dropdown {
  button,
  button:focus {
    background: #f3fcff !important;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }

  button::after {
    font-size: 0px !important;
    display: none;
    color: #000000 !important;
  }
}

.currency-dropdown .dropdown-menu {
  padding: 0.2rem !important;
}

.create-allocation {
  .main-heading {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
  }
  .sub-heading {
    font-size: 15px;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .form-data {
    margin-top: 30px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px;
      font-size: 14px;
    }
    input {
      height: 45px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 14px;
      color: #000000;
    }
    label {
      align-self: center;
      font-size: 14px;
      color: #000000;
    }
    .add-btn {
      float: right;
      width: 180px;
      height: 45px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .input-info {
      color: #000000;
      font-size: 12px;
      line-height: 30px;
    }
    .range-wrapper {
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 0px 8px 8px 0px;
      color: #f3fcff;
      font-size: 12px;
      font-weight: bold;
      margin-left: 1px;
      button,
      button:hover,
      button:active,
      button:focus {
        background: black !important;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        height: 100%;
      }
    }
    .range-wrapper-left {
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 0px 0px 0px 0px;
      color: #f3fcff;
      font-size: 14px;
      font-weight: bold;
      margin-left: 1px;
      button,
      button:hover,
      button:active,
      button:focus {
        background: black !important;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        height: 100%;
      }
    }
    .days-info {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #f76d67;
    }
  }
  .v-autocomplete .v-input__slot {
    background: #f3fcff;
    border: 1px solid #f1ecec;
    border-radius: 8px !important;
    height: 45px !important;
  }
  .dashboard-autocomplete {
    width: 100%;
    background-color: #f3fcff;
    padding-top: 0px !important;
    border-radius: 5px;
    font-size: 14px !important;
  }
}
</style>

<script>
// import { NumberSequence } from 'ag-grid-community';
import { mapGetters } from "vuex";
import ApiService from "../../../../core/services/api.service";
import Button from "../../vue-bootstrap/Button.vue";
export default {
  name: "create-allocation",
  props: ["ClientName", "type", "row", "campaignId"],
  components: { Button },
  data: () => ({
    constBudget: 0,
    varBudget: 0,
    budgetPercentage: 0,
    val: 0,

    // var for target
    constTarget: 0,
    varTarget: 0,
    targetPercentage: 0,
    targetVal: 0,

    startDate: "",
    dateDifference: 0,
    endDate: "",
    startCompleteDate: null,
    endCompleteDate: null,
    budget: "",
    totalBudget: "0",
    budgetPerValue: null,
    budget_currency: "AED",
    target: "",
    totalTarget: "0",
    targetPerValue: null,
    target_currency: "AED",
    isLoading: false,
    min: 12,
    selectedCamp: "",
    targetType: "",
    title: "",
    remaining_budget: 0,
    remaining_target: 0,
    client_id: null,
    targetValue: null,
  }),
  created() {
    if (this.type == "edit") {
      this.title = this.row.title;
      this.budget = this.row.budget;
      this.budget_currency = this.row.budget_currency;
      this.target = this.row.target;
      this.target_sales_currency = this.row.target_sales_currency;
      this.client_id = this.row.client_id;
      this.selectedCamp = this.campaigns.find((x) => x._id == this.campaignId);
      this.startDate = this.row.start;
      this.endDate = this.row.end;
    }
  },
  watch: {
    addBudget() {
      // this.budgetPerValue = (this.budgetPercentage / 100) * this.totalBudget;
    },
    selectedCamp() {
      this.targetVal = 0;
      this.targetPercentage = 0;
      if (this.type !== "edit") {
        this.startDate = "";
        this.endDate = "";
        this.dateDifference = 0;
      }
      this.totalBudget = this.selectedCamp.budget;
      ApiService.post("campaigns/totals", {
        client_id: this.$route.params.id,
        campaign_id: this.selectedCamp._id,
      }).then((res) => {
        console.log("respponse", res);
        this.targetType = res.data.data.target_type;
        this.remaining_budget =
          res.data.data.total_allocation_budget - res.data.data.total_budget;
        console.log("remaining budget", this.remaining_budget);
        this.remaining_target = res.data.data.total_target;
        console.log("remianing target", this.remaining_target);
        console.log("total target", res.data.data.total_target);
        console.log(
          "total_allocation_target",
          res.data.data.total_allocation_target
        );
        this.constBudget =
          res.data.data.total_allocation_budget - res.data.data.total_budget;
        this.varBudget = this.constBudget;
        // for target
        // (un-comment after go through with the functionality)
        this.constTarget = res.data.data.total_target;
        // this.constTarget = res.data.data.total_target;
        this.varTarget = this.constTarget;
        this.targetValue = this.constTarget;
      });
    },
  },
  mounted() {},
  methods: {
    decrease() {
      this.budgetPercentage--;
      this.val = this.constBudget * (this.budgetPercentage / 100);
      this.val = this.val.toFixed(2);
      this.varBudget = this.constBudget - this.val;
    },
    increase() {
      this.budgetPercentage++;
      this.val = this.constBudget * (this.budgetPercentage / 100);
      this.val = this.val.toFixed(2);
      this.varBudget = this.constBudget - this.val;
      // this.budgetPercentage++;
      // this.budget = (this.budgetPercentage / 100) * this.remaining_budget;
    },
    changeBudgetValue(e) {
      this.budgetPercentage = (e.target.value / this.constBudget) * 100;
      this.budgetPercentage = Math.round(this.budgetPercentage);
      this.val = e.target.value;
      this.varBudget = this.constBudget - e.target.value;
    },
    formatBudgetInput(e) {
      if (e < 0) return;
      if (e <= this.constBudget) {
        return e;
      }
      return this.val;
    },
    changeTargetValue(e) {
      if (this.constTarget <= 0) return;
      this.targetPercentage = (e.target.value / this.constTarget) * 100;
      this.targetPercentage = Math.round(this.targetPercentage);
      this.varTarget = this.constTarget - e.target.value;
    },
    formatTargetInput(e) {
      if (e < 0) return;
      if (e <= this.constTarget) {
        return e;
      }
      return this.targetVal;
    },
    calculateDateDiff() {
      if (!this.startCompleteDate || !this.endCompleteDate) return;
      let dateTime =
        this.endCompleteDate.getTime() - this.startCompleteDate.getTime();
      this.dateDifference = dateTime / (1000 * 3600 * 24);
      console.log(this.dateDifference);
    },

    decreaseTarget() {
      this.targetPercentage--;
      this.targetVal = this.constTarget * (this.targetPercentage / 100);
      this.targetVal = this.targetVal.toFixed(2);
      this.varTarget = this.constTarget - this.targetVal;
    },
    increaseTarget() {
      this.targetPercentage++;
      this.targetVal = this.constTarget * (this.targetPercentage / 100);
      this.targetVal = this.targetVal.toFixed(2);

      this.varTarget = this.constTarget - this.targetVal;
    },
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.calculateDateDiff();
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      this.endCompleteDate = ctx.activeDate;
      this.calculateDateDiff();
    },
    handleSubmit() {
      let dataToPost = {
        title: this.title,
        budget: this.val,
        budget_currency: "AED",
        target: this.targetVal,
        target_sales_currency: "AED",
        is_draft: false,
        client_id: this.$route.params.id,
        campaign_id: this.selectedCamp._id,
        start: this.startDate,
        end: this.endDate,
      };
      this.isLoading = true;
      // return
      if (this.type == "edit") {
        ApiService.put("/allocations", {
          ...dataToPost,
          allocation_id: this.row._id,
        }).then((response) => {
          this.isLoading = false;
          this.$emit("updated");
          window.location.reload();
          this.$bvModal.hide(`edit-allocation-modal`);
        });
      } else {
        ApiService.post("/allocations", dataToPost).then((response) => {
          console.log(response);
          this.isLoading = false;
          window.location.reload();

          this.$bvModal.hide(`create-allocation-modal`);
        });
      }
    },
  },
  computed: {
    ...mapGetters(["CampaignGetters"]),
    selectedCampaign() {
      return this.$store.getters.CampaignGetters;
    },
    isFormValidated() {
      if (this.startDate && this.endDate && this.val && this.selectedCamp) {
        return true;
      }
      return false;
    },

    ...mapGetters(["CampaignArrayGetters"]),
    campaigns() {
      return this.$store.getters.CampaignArrayGetters;
    },
  },
};
</script>
